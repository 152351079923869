export default {
    farkos: {},
    johnson: {
        delivery: {
            title: `Джонсон и Джонсон Украина`,
            buyingAnyProduct: `При покупке акционного товара`,
            discountTerm: `Акция действует до 31.03.2021 на сайте apteka24.ua`
        }
    }
};
