/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    banner: {
        title: `Програма «Морквинки» — відмінний спосіб економити`,
        description: `Кожен зареєстрований користувач сайту apteka24.ua є учасником бонусної програми і заробляє бонусні бали — «морквинки», які можна використовувати для оплати замовлень на сайті. При цьому «морквинками» можна оплачувати до 50% від вартості своїх покупок!`
    },
    howToGetBonus: {
        title: `Як отримати «морквинки»?`,
        promoDescription: `Перша «морквинка» <img src=${links.icons.carrotColored} alt="carrot" /> вже чекає на тебе.<br>
                       Отримай промокод <b>на знижку 7%</b> на першу покупку`,
        nextPoints: `Так, а тепер до справи...`,
        bonusRules: `<b>Залишайте корисний контент.</b> Пишіть відгуки про товари, які ви купуєте на apteka24.ua, і отримуйте від 10 грн на бонусний рахунок за кожен схвалений відгук. Крім того, ви заробите додаткову 1 грн за кожен лайк, залишений під вашим відгуком `,
        reviewRequirements: `Відгуки можуть бути як позитивними, так і негативними. Основна вимога до відгуку — максимальна інформативність. Чим більше корисної інформації для інших користувачів буде містити ваш відгук, тим більше лайків ви зможете зібрати`,
        cashbackRules: `<b>Купуйте на сайті apteka24.ua.</b> За кожну покупку ми повертаємо 2% від її вартості на ваш бонусний рахунок. Наприклад, купуючи упаковку ліків за 310.20 грн, ви отримаєте на бонусний рахунок цілих 6.20 грн! Це працює так, неначе товар коштує вам всього 304 грн — на 15 грн дешевше, ніж в іншій аптеці*`,
        note: `*Для порівняння взяті ціни на "Німесил гранули 2 г №30" на 01.03.2021 14:49 на сайтах apteka24.ua і «Першої соціальної» аптеки`
    },
    howToSpendBonus: {
        title: `Як витратити «морквинки»?`,
        instruction: `Кількість накопичених «морквинок» ви можете побачити у верхній частині сайту — над вікном пошуку, поряд з вашим «кошиком». Знижка за «морквинки» може застосовуватися спільно з іншими знижками, що діють на все замовлення`,
        bonusProducts: `«Морквинки» можна витрачати на оплату товарів з відповідним знаком (таких товарів вже більше 3000). А поруч з написом «Кешбек» зазначено, скільки «морквин» ви отримаєте за покупку`
    },
    promoCode: {
        title: `Промокоди`,
        description: `Промокод — це кодове слово, що дає право на отримання знижки при замовленні товару на apteka24.ua. Промокоди — одноразові та з обмеженим терміном дії. Тому, якщо у вас є промокод, краще використовувати його відразу`,
        waysToGet: {
            title: `Промокоди можна отримати:`,
            social: `Знайшовши їх на наших сторінках <a href=${links.facebookA24} target="_blank">Facebook</a> або <a href=${links.instagramA24} target="_blank">Instagram</a>`,
            partners: `На сайтах наших партнерів`,
            promotions: `Беручи участь у наших акціях`
        },
        ifCancelOrder: `У разі, якщо замовлення не буде викуплене, промокод не відновлюється`
    },
    lawyer: {
        title: `Офіційні правила програми лояльності «Морквинки»`,
        description: `<p>
                            Учасник програми (учасник) - фізична особа, яка підтвердила свій намір
                             брати участь в Програмі Лояльності (ПЛ), виконавши вимоги щодо вступу в ПЛ,
                             викладені в цих правилах ПЛ, а також фізична особа, яка виявила бажання стати
                             учасником ПЛ відповідно до правил ПЛ, але ще не підтвердила цей намір
                             виконанням всіх вимог щодо вступу в ПЛ, викладених в цих правилах ПЛ.
                        </p>
                        <p>
                            Учасником ПЛ може стати будь-яка фізична особа, яка досягла 18-річного віку і
                             яка проживає на території України.
                        </p>
                        <p>
                            Кожен учасник програми повинен ознайомитися з цими правилами ПЛ і виконувати
                             викладені в них вимоги.
                        </p>
                        <p>
                            Учасник погоджується з даними правилами, якщо він робить будь-яку активну дію
                             на сайті apteka24.ua (наприклад реєстрація, покупка, залишення відгуку і т.д.)
                        </p>
                        <p>
                            Персональними даними є інформація про учасника, надана самим
                             учасником або отримана Аптека24 іншим способом, що не суперечить закону,
                             яка дозволяє ідентифікувати цього учасника в рамках ПЛ.
                        </p>
                        <p>
                            Персональні дані учасників обробляються відповідно до вимог Закону
                             України «Про захист персональних даних» від 01.06.2010 р. №2297.
                        </p>
                        <p>
                            Учасник також дає згоду на використання його персональних даних для отримання
                             рекламних і спеціальних пропозицій, інформації про спеціальні акції, розіграші,
                             іншу інформацію про діяльність Аптека24. Для цілей, передбачених цим
                             пунктом, Аптека24 має право направляти листи, повідомлення та матеріали на
                             електронну поштову адресу (e-mail) учасника, а також відправляти SMS-повідомлення,
                             здійснювати дзвінки на вказаний телефонний номер в профілі учасника.
                        </p>
                        <p>
                            Аптека24 не несе відповідальності за шкоду або збитки, завдані учаснику або
                             третім особам в результаті помилкового розуміння або нерозуміння умов даних
                             правил, інструкцій або вказівок про порядок використання сайту, щодо
                             розміщення даних та інших технічних питань.
                        </p>
                        <p>
                            Відповідальність Аптека24 по відношенню до наданих в рамках ПЛ товарів і послуг
                             і відповідні гарантійні зобов'язання обмежені вимогами законодавства
                             України з урахуванням особливостей, передбачених даними правилами.
                        </p>
                        <p>
                            Відповідальність Аптека24 перед учасником за порушення цих правил ПЛ
                             обмежується в разі:
                        </p>
                        <p>
                            • будь-якої претензії, пов'язаної з нарахуванням або ненарахування бонусів / морквинок;
                        </p>
                        <p>
                            • претензії, пов'язаної з наданим товаром, обраним в якості привілеї
                             (Аптека24 має право замінити даний товар на інший, аналогічний).
                        </p>
                        <p>
                            <strong>
                                Обмеження
                            </strong>
                        </p>
                        <p>
                            Бонуси будуть згоряти через 180 днів від моменту, коли у користувача з'явиться
                             можливість їх витрачати.
                        </p>`
    }
};
