// import links from "app/core/resource/links";

export default {
    title: `Нашли ошибку на сайте?`,
    ourTarget: `Наша цель — предоставлять контент высочайшего качества и быть союзниками
                наших читателей на их пути к здоровью и благополучию. Поэтому мы хотим
                получать ваши отзывы и замечания, чтобы соответствовать высоким
                стандартам медицинского информационного ресурса.`,
    takeAction: `Когда читатель предупреждает нас о потенциальной проблеме с нашим
                контентом, например, о неполной, неточной, устаревшей, непонятной или
                противоречивой информации, мы стараемся незамедлительно принимать меры.`,
    editorialTeamWork: `Редакторская команда, включающая как редакцию, так и медицинских
                        экспертов, изучает отзывы, ищет пути исправления проблемы, вносит
                        соответствующие корректировки и повторно публикует контент с уже
                        внесенными необходимыми исправлениями.`,
    modal: {
        success: {
            title: "Спасибо что сообщили!",
            description: "Уже передали редакторам на проверку"
        },
        error: {
            title: "Ой, проблемы на сайте!",
            description: "Трижды глубоко вдохните и попробуйте снова :)"
        }
    }
};
