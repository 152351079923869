/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    properties: {
        title: "Характеристики",
        description: `
                    <div>
                        Характеристики товарів заповнюються відповідно до офіційним джерелом
                        <a href="${links.stateRegisterOfDrugs}" target="_blank">(Державний реєстр лікарських засобів України)</a>, 
                        є коротким відображенням основних характеристик препарату і містять такі пункти:
                      
                        <ul>
                            <li>АТС-Класифікація</li>
                            <li>рецептурний відпуск</li>
                            <li>термін придатності</li>
                            <li>дозування</li>
                            <li>упаковка</li>
                            <li>склад</li>
                            <li>показання</li>
                            <li>протипоказання</li>
                            <li>код Моріон</li>
                            <li>фармакотерапевтична група</li>
                            <li>діюча речовина</li>
                            <li>українська назва</li>
                        </ul>                  
                    </div>
                `
    },
    instruction: {
        title: "Джерела формування інструкцій",
        description: `
                    <p>
                        Дана інструкція відповідає дійсності і є достовірною.
                        Офіційне джерело: <a href="${links.stateRegisterOfDrugs}" target="_blank">Державний реєстр лікарських засобів України</a>
    
                        <span class="color-green f-weight-5"> [Офіційне джерело]</span>.
                        Публікація інструкції та описи здійснена на підставі

                        <a href="${links.editorialPolicy}" target="_blank"> редакційної політики </a>
                        Медмаркета Аптека24,
                        відповідає <a href="${links.lowOfUkraineAboutDrugs}" target="_blank">Закону Украины «Про лікарські засоби»</a>
    
                        <span class="color-green f-weight-5"> [Офіційне джерело] </span>
    
                        і <a href="${links.lowOfUkraineAboutCopyright}" target="_blank">Закону Украины «Об авторском праве и смежных правах»</a>
    
                        <span class="color-green f-weight-5"> [Офіційне джерело]</span>,
    
                        а також перевірена уповноваженим цензором. Оновлення інструкції на сайті apteka24.ua
                        здійснюється після її поновлення на сайті Державного реєстра лікарських засобів України,
                        або на офіційному сайті виробника
                    </p>
                `
    },
    description: {
        title: "Опис лікарського засобу",
        description: `
                    Даний опис грунтується на офіційній інструкції або маркетинговому матеріалі з офіційного
                    сайту виробника і є доступним для пацієнта інформуванням про важливі властивості,
                    взаємодіях, клінічних характеристики та особливості лікарського засобу
                `
    },
    analogs: {
        title: "Алгоритм підбору аналогів лікарського засобу",
        description: `
                    <div>
                        Підбір аналогів лікарського засобу на сайті Медмаркета Аптека24 відбувається за алгоритмом,
                        затвердженим <a href="${links.whocc}" target="_blank">центрами ВООЗ за методологією статистики лікарських засобів</a> 
                        і прийнятому Міністерством охорони здоров'я України:
                        
                        <ul>
                            <li>
                                перший рівень: аналогічні лікарські засоби з тією самою діючою речовиною, дозуванням і формою випуску
                            </li>
                            <li>
                                другий рівень: аналогічні лікарські засоби з тією самою діючою речовиною, дозуванням, але з іншою формою випуску
                            </li>
                            <li>
                                третій рівень: аналогічні лікарські засоби з тією самою діючою речовиною, але з іншого дозуванням і іншою формою випуску
                            </li>
                            <li>
                                четвертий рівень: аналогічні лікарські засоби, підібрані відповідно до АТС-класифікації, які можуть містити інше діюча речовина, іншу дозування і іншу форму випуску, але надають схожий клінічний ефект і можуть застосовуватися для лікування того ж захворювання або мають вплив на ту ж систему органів або органів
                            </li>
                        </ul>                  
                    </div>
                `
    },
    mainProperties: {
        title: "Основні фізико-хімічні властивості",
        description: `
                    Даний блок призначений для інформування про виробника, класифікації лікарського
                    засоби по температурному режиму зберігання, а також про форму випуску,
                    обсяг та кількість в упаковці
                `
    },
    certificate: {
        title: "Сертифікат якості",
        description: `
                    Даний сертифікат дозволяє упевнитися в якісних характеристиках лікарського
                    засоби і в його відповідності властивостям, заявленим в інструкції
                `
    }
};
