// import links from "app/core/resource/links";

export default {
    title: `Знайшли помилку на сайті?`,
    ourTarget: `Наша мета - надавати контент високої якості і бути союзниками
                 наших читачів на їх шляху до здоров'я і благополуччя. Тому ми хочемо
                 отримувати ваші відгуки та зауваження, щоб відповідати високим
                 стандартам медичного інформаційного ресурсу.`,
    takeAction: `Коли читач попереджає нас про потенційну проблему з нашим
                 контентом, наприклад, про неповну, неточну, застарілу, незрозумілу або
                 суперечливу інформацію, ми намагаємося негайно вживати заходів.`,
    editorialTeamWork: `Редакторська команда, що включає як редакцію, так і медичних
                         експертів, вивчає відгуки, шукає шляхи виправлення проблеми, вносить
                         відповідні коригування та повторно публікує контент з уже
                         внесеними необхідними виправленнями.`,
    modal: {
        success: {
            title: "Дякуємо, що повідомили!",
            description: "Вже передали редакторам на перевірку"
        },
        error: {
            title: "Ой, проблеми на сайті!",
            description: "Тричі глибоко вдихніть і спробуйте знову :)"
        }
    }
};
