export default {
    plural: {
        review: ["отзыв", "отзыва", "отзывов"],
        answers: ["ответ", "ответа", "ответов"],
        days: ["день", "дня", "дней"],
        hours: ["час", "часа", "часов"],
        minutes: ["минута", "минуты", "минут"],
        seconds: ["секунда", "секунды", "секунд"],
        products: ["товар", "товара", "товаров"],
        coupons: ["купон", "купона", "купонов"]
    },
    errors: {
        server: {
            default: {
                title: "Ой, что-то пошло не так",
                description: "Пожалуйста, повторите попытку через 30 секунд"
            }
        },
        delivery: {
            notAvailable: "К сожалению, нет доступных способов доставки выбранных товаров в этот город"
        }
    },
    calendar: {
        months: ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"]
    },
    review: {
        about: "Отзывы о {0}",
        useful: "Полезный отзыв",
        useless: "Бесполезный отзыв",
        reviewUseful: "Отзыв полезен",
        comment: {
            commentUseful: "Комментарий полезен",
            useful: "Полезный комментарий",
            useless: "Бесполезный комментарий"
        },
        buyersReviews: "Отзывы покупателей",
        viewAll: "Смотреть все отзывы",
        create: "Оставить отзыв",
        loginAndCreate: "Войти и оставить отзыв",
        add: "Дополнить страницу отзывами",
        aboutProduct: "Отзыв о товаре",
        sendAnswer: "Отправить ответ",
        youHaveNotLeftAnyProductReviewsYet: "Вы еще не оставляли отзывы о товарах",
        createYourFirstOrderToLeaveAReview: "Самое время создать первый заказ, чтобы оставить отзыв",
        messages: {
            receivedReview: "Получили Ваш отзыв",
            receivedAnswer: "Получили Ваш ответ"
        },
        allReviews: "Все отзывы",
        firstReviewIsTheMostValuable: "Первый отзыв – самый ценный",
        reviewIsTheMostValuable: "Ваш отзыв очень ценен для нас",
        youHaveAlreadyPurchasedThisItem: "Вы уже покупали этот товар",
        dontShowMyName: "Не указывать мое имя",
        write: "Написать отзыв"
    },
    myOrders: {
        title: "Мои заказы",
        youHaveNoOrders: "Эх, ни одного заказа… зато смотрите что тут есть",
        promoCodeForTheFirstOrder: "Промокод на -5% на первый заказ",
        letIsOrderSomethingForAHomeFirstAidKit: "Ну что, закажем что-то для домашней аптечки",
        justWriteDrugNameInTheSearch: "Просто напишите в поиске название препарата"
    },
    validation: {
        enterYourRating: "Укажите Вашу оценку",
        enterCorrectPhoneNumber: "Укажите корректный номер телефона",
        enterCorrectSymbolsForRuOrUkLanguage: "Допустимы символы русского и украинского алфавита",
        enterCorrectEmail: "Укажите корректный email-адрес",
        enterCorrectFlatNumber: "Укажите корректный номер квартиры",
        mustBeCompleted: "Обязательно должно быть заполнено",
        errorAvatarUpload: "Фото профиля не загружено. Нужна картинка в формате jpeg, png или gif, размером до 10 МБ.",
        errorUploadUnpackingPhoto: "Невозможно загрузить фото! Загрузка фото запрещена по одной из следующих причин: неверный формат изображения (допустимы форматы jpg, png); размер фото больше необходимого (более 15 мб); количество фото превышает допустимое (10 изображений); фотография распознана как нецензурного характера."
    },
    oh: "Ой",
    youHaveAlreadyApplied: "Вы уже подавали заявку",
    pageNotFound: "Страница не найдена",
    allAboutProduct: "Все о товаре",
    allRoundView: "Круговой обзор для",
    analogs: "Аналоги",
    analogsAndSubstitutes: "Аналоги и заменители",
    analogsAndSubstitutesFor: "Аналоги и заменители для {0}",
    analogsFromOtherVendors: "Аналоги от других производителей",
    youMayAlsoBeInterestedIn: "Также вас могут заинтересовать",
    seeAllAnalogs: "Смотреть все аналоги",
    viewAll: "Смотреть все",
    view: "Смотреть",
    all: "Все",
    morkovki: "Морковки",
    favorites: "Избранное",
    anotherUser: "Другой пользователь",
    personalAccount: "Личный кабинет",
    privateData: "Личные данные",
    personalData: "Персональная информация",
    productCatalog: "Каталог товаров",
    viewedProducts: "Просмотренные товары",
    attentionToThisItems: "Обратите внимание",
    bestsellers: "Хиты продаж",
    popularBrands: "Популярные бренды",
    lastReviews: "Последние отзывы",
    usefulArticles: "Полезные статьи",
    reviews: "Отзывы",
    vacancies: "Вакансии",
    contacts: "Контакты",
    contactDetails: "Контактная информация",
    deliveryType: "Способ доставки",
    choiceDeliveryType: "Выбор способа доставки",
    chooseDepartment: "Выберите отделение",
    chooseDrugstore: "Выберите аптеку",
    choicePaymentType: "Выбор способа оплаты",
    myReviews: "Мои отзывы",
    myBonuses: "Мои бонусы",
    carrotProgram: "Программа лояльности «Морковки»",
    bonuses: "Бонусы",
    updateBonuses: "Обновить бонусы",
    useBonuses: "Использовать бонусы",
    wantToSpendOnThisOrder: "Хотите потратить на этот заказ",
    amountOfBonusWithoutValue: "Бонусных",
    howToSpendBonuses: "Как тратить бонусы",
    orderHistory: "История заказа",
    trackYourOrder: "Отследить заказ",
    shareRules: "Правила акции",
    orderNumber: "Номер заказа",
    onYourBalance: "На Вашем счету",
    wishList: {
        title: "Список желаний",
        emptyList: "Ваш список желаний пуст",
        addProducts: "Добавьте в него товары"
    },
    next: "Далее",
    back: "Назад",
    exit: "Выход",
    registration: "Зарегистрироваться",
    joinUs: "Присоединяйтесь",
    clearlyUnderstood: "Понятно",
    pity: "Жаль",
    hotline: "Горячая линия",
    phone: "Телефон",
    phoneNumber: "Номер телефона",
    sentSMSWithCode: "На указаный Вами номер телефона было отправлено смс с паролем",
    enterCode: "Введите код",
    enterCodeFromSMSOrViber: "Введите код проверки из SMS или Viber",
    sendCodeAgain: "Выслать код еще раз",
    enterSettlementName: "Введите наименование населенного пункта",
    enterAddressOrDepartmentNumber: "Введите адрес или номер отделения",
    availableThrough: "Доступно через",
    mondSund: "пн-вс",
    userAgreement: {
        title: "Пользовательское соглашение",
        acceptTerms: "Продолжая, Вы принимаете условия",
        confirmOrder: "Подтверждая заказ, я принимаю условия",
        agreement: "Пользовательского соглашения"
    },
    paymentTypes: {
        cash: "Наличными",
        cashOnDelivery: "Наложенный платеж",
        creditCardOnSite: "Оплата картой на сайте"
    },
    deliveryTypes: {
        courier: "Курьер по вашему адресу",
        selfDelivery: "Получение заказа в аптеке",
        departmentNovaPoshta: "«Нова Пошта»: до отделения",
        courierFromNovaPoshta: "«Нова Пошта»: по указанному адресу",
        departmentUkrPoshta: "«Укрпошта»: до отделения",
        courierFromUrkPoshta: "«Укрпошта»: по указанному адресу",
        departmentJustIn: "«Justin»: до отделения",
        departmentMeest: "«Meest»: доставка в отделение",
        courierFromMeest: "«Meest»: доставка курьером",
        departmentiPOST: "iPOST: до отделения",
        courierFromiPOST: "iPOST: доставка курьером",
        courierFromUklon: "Uklon: доставка курьером",
        withoutDelivery: "Без доставки"
    },
    propertiesOfDrug: "Свойства препарата",
    instructionForUse: "Инструкция по применению",
    instruction: "Инструкция",
    instructionFromVendor: "Инструкция производителя",
    description: "Описание",
    videoReview: "Видеообзор",
    loginOrRegistration: "Вход/Регистрация",
    loginToYourAccount: "Войдите в личный кабинет",
    logIn: "Войти",
    promotions: "Акции",
    promotionalProducts: "Товари акції",
    brands: "Бренды",
    searchDrug: "Поиск препарата",
    searchByBrand: "Поиск по бренду",
    searchByActiveIngredient: "Поиск по действующему веществу",
    insuranceCovid: "Страховка от COVID-19",
    searchDrugsByActiveIngredient: "Поиск лекарств по действующему веществу",
    selectionDrugsByActiveIngredientOnline: "Поиск лекарств по действующему веществу онлайн",
    drugstores: "Аптеки",
    partners: "Партнеры",
    ourPartners: "Наши партнёры",
    ourTeam: "Наша команда",
    deliveryInformation: "Информация о доставке",
    deliveryAndPayment: "Доставка и оплата",
    qualityAssurance: "Гарантии качества",
    returnConditions: "Условия возврата",
    whatMakesUsDifferent: "Чем мы отличаемся",
    aboutUs: "О нас",
    blog: "Блог",
    covid: "Covid-19",
    bonusesByReviews: "Бонусы за отзывы",
    notField: "Не указано",
    hideText: "Скрыть текст",
    readMore: "Читать далее",
    readAnother: "Читать ещё",
    readAll: "Читать всё",
    readAllAnswers: "Читать все ответы",
    readCompletely: "Читать полностью",
    readArticle: "Читать статью",
    save: "Сохранить",
    cancel: "Отмена",
    edit: "Редактировать",
    editOrder: "Редактировать заказ",
    errorNotificationOrder: "В данный момент невозможно создать заказ. Пожалуйста, попробуйте позже.",
    toAnswer: "Ответить",
    name: "Имя",
    lastName: "Фамилия",
    middleName: "Отчество",
    birthday: "Дата рождения",
    gender: "Пол",
    communicationLanguage: "Язык общения с apteka24.ua",
    languageSelection: "Выбор языка",
    about: "О себе",
    ru: "Русский",
    uk: "Украинский",
    male: "Мужской",
    female: "Женский",
    profilePhoto: "Фото профиля",
    change: "Изменить",
    show: {
        default: "Показать",
        more: "Показать ещё",
        all: "Показать все",
        less: "Скрыть"
    },
    informationAboutProduct: "Информация о товаре",
    informationAboutOrder: "Информация о заказе",
    informationAboutCompany: "Информация о компании",
    orderPrice: "Сумма заказа",
    warningOrderPriceChange: "Обратите внимание сумма заказа изменилась",
    orderDate: "Дата заказа",
    productsPrice: "Стоимость товаров",
    sendOrder: "Отправить заказ",
    deliveryPrice: "Сумма доставки",
    quantity: "Количество",
    or: "Или",
    from: "От",
    amount: "Сумма",
    forAmount: "На сумму",
    payment: "Оплата",
    paymentInformation: "Информация об оплате",
    paymentType: "Способ оплаты",
    total: "Итого",
    changeOrderToPayment: "Изменения стоимости заказа",
    totalForPayment: "Итого к оплате",
    checkout: "Оформить заказ",
    orderCreation: "Оформление заказа",
    orderDetails: "Детали заказа",
    orderItems: "Заказанные товары",
    findDrugs: "Найти лекарство",
    learnMore: "Узнать подробнее",
    send: "Отправить",
    moreDetails: "Подробнее",
    moreDetailsAboutBonuses: "Подробнее о бонусах",
    sort: "Сортировать",
    reset: "Сбросить",
    clear: "Очистить",
    clearList: "Очистить список",
    yourName: "Ваше имя",
    yourLastName: "Ваша фамилия",
    yourEmail: "Ваш e-mail",
    yourPhone: "Ваш телефон",
    yourMessage: "Ваше сообщение",
    yourComment: "Ваш комментарий",
    frequentlyAskedQuestions: "Часто задаваемые вопросы",
    didWeAnswerYourQuestion: "Мы ответили на Ваши вопросы?",
    ifNotLetUsKnow: "Если нет, пожалуйста, дайте нам знать",
    notifyOfRepliesByEmail: "Уведомлять об ответах по электронной почте",
    cities: "Города",
    pharmaciesForIssue: "Аптеки для выдачи",
    drugsDelivered: "Лекарств доставлено",
    customers: "Покупатели",
    regularCustomer: "Я постоянный клиент",
    recipient: "Получатель",
    recipientIsADifferentPerson: "Получатель другой человек",
    freeChatWithPharmacist: "Бесплатный чат с опытным фармацевтом",
    howWeWork: "Как мы работаем",
    discount100UAH: "Скидка 100 грн",
    doctorsAboutProject: "Врачи о проекте",
    recommend: "Рекомендованые",
    recommendedForPurchase: "Рекомендуем к покупке",
    continueShopping: "Продолжить покупки",
    backToShopping: "Вернуться к покупкам",
    ascendingPrice: "По возрастанию цены",
    descendingPrice: "По убыванию цены",
    youChoose: "Вы выбрали",
    warning: "Внимание",
    vendorCard: {
        contacts: {
            mainOffice: "Главный офис",
            phone: "Телефон",
            email: "e-mail",
            fax: "Факс",
            site: "Сайт"
        },
        aboutCompany: "О компании",
        johnson: {
            name: "ООО «Джонсон и Джонсон Украина»"
        },
        farkos: {
            name: "Фармацевтическая компания «ФарКоС»"
        }
    },
    buyInOneClick: "Купить в один клик",
    buy: "Купить",
    alreadyBought: "Уже купил(а)",
    reviewByMobileApp: "Отзыв оставлен из мобильного приложения",
    inBasket: "В корзине",
    basket: "Корзина",
    addToBasket: "Заказать",
    toBasket: "Перейти в корзину",
    toBuyer: "Покупателю",
    company: "Компания",
    companyRepresentative: "Представитель компании",
    officialRepresentativeOfApteka24: "Официальный представитель Аптека24",
    toPartners: "Партнерам",
    weAreInSocials: "Мы в социальных сетях",
    socialNetworks: "Социальные сети",
    massMediaAboutUs: "СМИ о нас",
    doctorsAboutTheService: "Врачи о сервисе",
    vitaminsAndMinerals: "Витамины и минералы",
    popularProducts: "Популярные товары",
    antibiotics: "Антибиотики",
    gynecologicalPreparations: "Гинекологические препараты",
    urologicalPreparations: "Урологические препараты",
    links: "Ссылки",
    logoutFromAccount: "Выйти из аккаунта",
    openOriginalImage: "Открыть большое изображение",
    filter: "Фильтр",
    filters: "Фильтры",
    activeIngredient: "Активный ингредиент",
    seller: "Продавец",
    sellerPrice: "цена",
    sellerOfProduct: "Продавец товара",
    categories: "Категории",
    subcategories: "Подкатегории",
    allCategories: "Все категории",
    since: "с",
    price: "Цена",
    priceFor: "Цена на",
    prices: "Цены",
    emptySearchResult: "Ничего не найдено, попробуйте изменить запрос",
    youMeant: "Возможно, вы имели в виду",
    selectedAll: "Выбрать все",
    select: "Выбрать",
    apply: "Применить",
    accept: "Принять",
    removeSelection: "Сбросить выбор",
    delete: "Удалить",
    toHomePage: "Перейти на главную",
    mobileApp: "Мобильное приложение",
    informAboutAvailability: "Сообщить, когда появится",
    willInformAboutAvailability: "Сообщим о наличии",
    legitimnost: "Легитимность",
    ourMission: "Наша миссия",
    ourAwards: "Наши награды",
    ourAdvantages: "Наши преимущества",
    socialPrograms: "Социальные программы",
    medicalAnswers: "Медицинские ответы",
    medicalExperts: "Медицинские эксперты",
    cooperationProposals: "Предложения о сотрудничестве",
    partnerAccount: "Личный кабинет партнёра",
    forPregnantAndBreastFeeders: "Беременным и кормящим грудью",
    duringPregnancyAndBreastfeeding: "При беременности и грудном вскармливании",
    howToOrderDrugs: "Как заказать лекарства",
    editorialPolicy: "Редакционная политика",
    reviewsPostingPolicy: "Политика публикации отзывов",
    marketingPolicy: "Маркетинговая политика",
    showMoreInstruction: "Смотреть всю инструкцию",
    showMoreDescription: "Смотреть всё описание",
    showMoreProperties: "Смотреть все характеристики",
    showMoreHistory: "Смотреть всю историю",
    found: "Найдено",
    popularCategories: "Популярные категории",
    free: "Бесплатно",
    freeOverUkraine: "Бесплатно по Украине",
    deliveryCompany: "Транспортная компания",
    orderUpTo: "Заказ до",
    orderFrom: "Заказ от",
    currency: {
        uah: "грн"
    },
    orders: "Заказы",
    deliveryTo: "Доставка в",
    aboutDeliveryLocations: "Мы возим заказы во все населенные пункты Украины",
    enterCityNameOrVillage: "Укажите интересующий Вас город или посёлок, чтобы увидеть доступные способы доставки",
    cashbackForPurchases: "Кешбэк за покупки",
    discountPromoCodes: "Промокоды на скидки",
    promoCode: "Промокод",
    supportGroupAndPharmacist: "Служба поддержки и связь с фармацевтом",
    giftCoupons: "Подарочные купоны",
    referralProgram: "Реферальная программа",
    freeDelivery: "Бесплатная доставка",
    drugBenefits: "Преимущества препарата",
    privacyPolicy: "Политика конфиденциальности",
    download: "Скачать",
    downloadApp: "Скачивайте приложение",
    cityNames: {
        kiev: "Киев",
        kharkov: "Харьков",
        dnepr: "Днепр",
        odessa: "Одесса",
        rovno: "Ровно",
        vinnitsa: "Винница",
        zaporozhe: "Запорожье",
        ivanoFrankovsk: "Ивано-Франковск",
        kramatorsk: "Краматорск",
        kremenchug: "Кременчуг",
        krivoyRog: "Кривой Рог",
        lvov: "Львов",
        nikolaev: "Николаев",
        poltava: "Полтава",
        sumy: "Сумы",
        ternopol: "Тернополь",
        kherson: "Херсон",
        cherkassy: "Черкассы",
        chernovtsy: "Черновцы",
        chernigov: "Чернигов"
    },
    createApplication: "Оставить заявку",
    validity: "Срок действия",
    filling: "Наполнение",
    conditions: "Условия",
    priceFrom: "Цена от",
    priceTo: "Цена до",
    deliveryTime: "Время доставки",
    deliveryPriceLimitCourier: "Доставка курьером доступна при заказе на сумму от",
    workTime: "График работы",
    supportService: "Служба поддержки",
    warranty: "Гарантия",
    code: "Код",
    productCode: "Код товара",
    genericDrug: "Препарат дженерик",
    generic: "Дженерик",
    thankYouForTheOrder: "Спасибо за заказ",
    yourOrderHasBeenCompleted: "Мы приняли Ваш заказ",
    checkTheAvailabilityOfAllProducts: "Проверяем наличие всех товаров",
    ourManagerWillContactYouShortly: "Наш менеджер свяжется с вами в скором времени",
    ourPharmacistWillContactYouForConsultation: "Наш фармацевт позвонит вам в ближайшее время для согласования заказа. Через несколько минут мы отправим подтверждение и номер вашего заказа в sms или viber",
    ourPharmacistWillContactYouIfNecessary: "Ваш заказ будет обработан автоматически, в случае необходимости с вами свяжется фармацевт. Через несколько минут мы отправим подтверждение и номер вашего заказа в sms или viber",
    iNeedAPharmacistConsultationOnMyOrder: "Мне нужна консультация фармацевта по моему заказу",
    thank: "Спасибо",
    callYouToClarifyOrderDetails: "Вот-вот позвоним Вам, чтобы уточнить детали",
    chooseYourCity: "Выберите свой город",
    vendor: "Производитель",
    countryOfManufacture: "Страна-производитель",
    doctorsAnswersYourQuestions: "Ответы врачей на Ваши вопросы",
    officialAnswer: "Официальный ответ",
    sources: "Источники",
    similarQuestions: "Похожие вопросы",
    amountInTheCatalog: "{0} шт. в каталоге",
    amountOfThingsYouNeedToKnow: "{0} вещей, которые вам нужно знать",
    amountOfBonusMoney: "{0} бонусных гривен",
    recommendations: "Рекомендации",
    editorialStaff: "Редакция",
    censor: "Цензор",
    updated: "Обновлено",
    created: "Создано",
    verified: "Проверено",
    yourDataIsBeingVerified: "Ваши данные на проверке",
    home: "Главная",
    notAvailable: "Нет в наличии",
    setRate: "Поставьте оценку",
    allSearchResults: "Все результаты поиска",
    searchHistory: "История поиска",
    youMayBeLookingFor: "Возможно, Вы ищете",
    priceAndAvailabilityAreRelevantTo: "Цена и наличие актуальны на",
    certificate: "Сертификат",
    allArticles: "Все статьи",
    redaction: "Редакция",
    bonusesUsed: "Использовано бонусов",
    redactionFrom: "Редакция от",
    historyBonusTransactions: "История начисления и списания бонусов",
    youDontHaveAnyBonusesYet: "У Вас пока нет бонусов",
    accumulateBonuses: "Накапливайте бонусные гривны и используйте их при оплате своих покупок",
    pricesFor: "Цены на",
    cashback: "Кешбэк",
    returnToBonusAccount: "Вернется на бонусный счет",
    informed: "Ознакомлен",
    carefully: "С осторожностью",
    today: "Сегодня",
    tomorrow: "Доставим завтра",
    street: "Улица",
    house: "Дом",
    flat: "Квартира",
    lookAt: "Посмотреть",
    address: "Адрес",
    enterAddress: "Укажите адрес",
    whereLookingForEnterAddress: "Где искать? Введите адрес тут",
    searchByAddressOrDrugstoreName: "Поиск по адресу или названию сети",
    drugstoresList: "Список аптек",
    yourCity: "Ваш город",
    allCities: "Все города",
    asList: "Списком",
    asMap: "На карте",
    showDrugstoresByCity: "Отобразить аптеки по населенному пункту",
    showMoreCities: "Еще {0} городов",
    severalDrugstores: "Несколько аптек",
    partnerDrugstores: "Партнёрские аптеки",
    selfDeliveryPoint: "Пункт самовывоза",
    freeSelfDeliveryFromDrugstores: "Бесплатный самовывоз из аптек",
    drugstoreAddress: "Адрес аптеки",
    howToGetThere: "Как добраться",
    seeOnTheMap: "Смотреть на карте",
    searchRadius: "Радиус поиска",
    howPlaceOrderToDrugstore: "Как сделать заказ в эту аптеку",
    findAllDrugsYouNeed: "Найти все необходимые препараты на нашем сайте",
    addToCartAndStartCheckout: "Добавить их в корзину и начать оформлять заказ",
    chooseDrugstoreInDeliveryMethods: "Выбрать эту аптеку в способах доставки",
    orYouCanPlaceAnOrderByPhone: "Или можно оформить заказ по телефону",
    codeCopied: "Код скопирован",
    toShopping: "За покупками",
    wantMoreBenefits: "Вау, хочу больше преимуществ",
    examplesOfTasks: "Примеры заданий",
    rightNowYouCan: "Прямо сейчас можно",
    registeredEmployees: "Заявок подано",
    bonusesAccrued: "Бонусов начислено",
    giftsLeft: "Осталось купонов",
    restOfTime: "Осталось",
    afterOfTime: "Через",
    sendRequest: "Отправить заявку",
    saving: "Экономия",
    likes: "Лайки",
    toDefine: "Определить",
    toDefineAutomatically: "Определить автоматически",
    watchChannel: "Смотреть канал",
    aboutDoctor: "О враче",
    articles: "Статьи",
    expert: "Эксперт",
    registrationDate: "Дата регистрации",
    expertInTopic: "Эксперт в теме",
    certificates: "Сертификаты",
    receive: "Получить",
    fine: "Хорошо",
    doctorsCertificate: "Сертификат врача",
    addPhoto: "Добавить фотографии",
    addPhotoDescription: "Добавляйте до 10 изображений в форматах .jpg, .png, размером файла до 15 МБ",
    images: "Изображения",
    usersPhotos: "Фотографии пользователей",
    pcs: "Шт",
    detailedInformation: "Подробная информация",
    content: "Содержание",
    usersPhoto: "Фотографии покупателей",
    seeAllPhotos: "Посмотреть все изображения",
    goToGallery: "Перейти в галерею",
    topNegativeReview: "Лучший отрицательный отзыв",
    topPositiveReview: "Лучший положительный отзыв",
    uploadPhoto: "Загрузить фотографии",
    done: "Готово",
    almostDone: "Почти готово",
    uii: "Уии",
    letsStart: "Ну что, начнем",
    changeName: "Изменить имя",
    originalProduct: "Оригинальный препарат",
    showMore: "Показать ещё",
    will: "Будет",
    fromYou: "От вас",
    distance: {
        km: "км",
        m: "м"
    },
    takeAway: "Забрать",
    takeAwayToday: "Соберём за 15 мин",
    please: "Пожалуйста",
    toApplyPromoCodeOrViewBonuses: "Для применения промокода или просмотра бонусов",
    expandSearchRadius: "Расширьте радиус поиска",
    willDeliver: "Доставим",
    consultationViaMessengers: "Вы можете обратиться за консультацией фармацевта или по любому другому вопросу к нам используя удобный для Вас мессенджер",
    redemptionOfElectronicPrescriptions: "Погашение электронных рецептов",
    salesRules: "Правила осуществления продаж",
    documentsForConfirmationMinimumAge: "Клиента могут спросить документы подтверждающие, что ему 14 лет",
    license: "Лицензия",
    requisites: "Реквизиты",
    infoAboutOrderStatusToTheSection: "Чтобы получить информацию о текущем состоянии заказа перейдите в раздел",
    repeatOrder: "Повторить заказ",
    governmentalSupport: "Этот заказ можно оплатить картой «єПідтримка»!",
    inAppStore: "В App Store",
    inGooglePlay: "В Google Play",
    paymentMethod: "Оплата в аптеке",
    sellerTitle: "Цена товара зависит от продавца",
    basketTabPricePharmacy: "Итого к оплате"
};
