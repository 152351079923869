/* eslint-disable max-len */
export default {
    about: {
        title: `Електронна медична інформаційна система apteka24.ua — майже як інтернет-аптека, тільки краще!`,
        description: `
            <p>
                Електронна медична інформаційна система apteka24.ua — це пошук медичних товарів широкого асортименту в аптеках вашого міста, низькі ціни на ліки та зручна доставка. Можливо, на перший погляд, ми чимось схожі на інтернет-аптеку, але насправді на apteka24.ua ви зможете знайти більше ліків та більше зручних способів отримання замовлення.            
            </p>
            
            <p>
                Отримати своє замовлення ви можете у будь-якій партнерській аптеці у вашому місті — це може бути найближча аптека до вашої роботи чи дому. Або ж ви можете замовити кур'єрську доставку та отримати товари там, де вам зручно, і тоді, коли вам зручно.
            </p>
            
            <p>
                Усі мед.препарати наших аптек-партнерів мають сертифікати якості, а також зберігаються та перевозяться відповідно до необхідних умов зберігання та транспортування та температурного режиму. Тому ви можете бути впевнені, що замовляєте якісні препарати як оригінали, так і дженерики.
            </p>
            
            <p>
                Телефонуйте за безкоштовним номером 0800 30 22 44, і ви отримаєте докладну інформацію про наявність ліків в аптеках вашого міста від наших фармацевтів. Ми працюємо для вашого здоров'я!
            </p>
        `,
        advantages: {
            title: `У чому переваги apteka24.ua?`,
            hugeAssortment: `Понад 20 тисяч медпрепаратів з каталогу можна замовити онлайн для отримання в найближчій до вас аптеці. Тут представлені як популярні, так і рідкісні лікарські засоби, які важко знайти у більшості аптечних пунктів. Список доступних товарів постійно розширюється новими найменуваннями.`,
            delivery: `Попри те, що ми не є інтернет-аптекою, ми можемо організувати доставку по Україні. Замовлення можна отримати в найкоротші терміни у будь-якому населеному пункті за допомогою кур'єрських служб «Укрпошта» та «Нова Пошта».`,
            discounts: `Знижки на ліки. Ціна знижується в осінньо-зимовий сезон на протизастудні медикаменти, а влітку — на протиалергічні.`,
            qualityCertificates: `Продукція має сертифікати якості, які можна замовити в аптеці, щоб переконатися в надійності при отриманні.`,
            medicalMarket: `Більше, ніж просто аптека. Крім того, ми є інформаційним порталом з корисним та інформативним блогом, який веде команда медичних експертів, яка до того ж консультує наших клієнтів.`
        },
        assortment: {
            title: `Які товари можна придбати за допомогою apteka24.ua?`,
            productsFromCatalog: `Ви можете звернутися до нас з готовим рецептом лікаря, який спеціалісти-фармацевти контакт-центру apteka24.ua розшифрують та сформують точне замовлення. Або ж ви можете самостійно підібрати потрібні ліки та замовити онлайн у найближчу аптеку.`,
            productsHasInstruction: `Усі медикаменти містять точне зображення упаковки препарату та детальну інструкцію з усією необхідною медичною інформацією. Це дозволить легко визначитись із вибором.`,
            youCanFindAllDrugs: `Ви можете купити всі необхідні ліки, замовивши їх через електронну медичну інформаційну систему apteka24.ua, ніби побувавши в інтернет-аптеці. Широкий вибір товарів аптечного асортименту представлений такими категоріями:`,
            drugList: `
                <li>препарати від застуди, антибіотики, гінекологічні, гематологічні та гормональні препарати, засоби від алергії, для гарної шкіри, серцево-судинної системи, гепатопротектори, препарати для нервової системи та знеболювальні засоби;</li>
                <li>вітаміни та дієтичні добавки для міцного здоров'я;</li>
                <li>продукція для мам та дітей (пристосування для годування, продукція, що підвищує лактацію, та різноманітні дитячі чаї);</li>
                <li>косметика та гігієнічна продукція з догляду за собою;</li>
                <li>товари медичного призначення (медична техніка, маніпуляційні, діагностичні та перев'язувальні засоби, ортопедичні пристрої, компресійний трикотаж та медичний одяг);</li>
                <li>продукція для здоров'я очей (контактні лінзи та краплі для очей) та інше.</li>
            `
        },
        howToGetOrder: {
            title: `Як отримати замовлення, оформлене онлайн?`,
            suggestMethods: `Ми пропонуємо такі способи отримання замовлень:`,
            suggestMethodsList: `<li>самовивіз із аптек-партнерів;</li>
                            <li>служби доставки «Укрпошта» та «Нова пошта».</li>`,
            responsibility: `Коли ви замовляєте ліки онлайн через електронну медичну інформаційну систему apteka24.ua, ви можете бути впевнені, що отримаєте замовлення з сертифікованими товарами у зручний для вас час, тому що ми співпрацюємо з кількома сотнями аптек-партнерів, завдяки яким клієнти отримують можливість бронювання ліків з полиці найближчої аптеки.`
        }
    }
};
