/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    banner: {
        title: `Программа «Морковки» – отличный способ экономить`,
        description: `Каждый зарегистрированный пользователь сайта apteka24.ua является участником бонусной программы и зарабатывает бонусные баллы – «морковки», которые можно использовать для оплаты заказов на сайте. При этом «морковками» можно оплачивать до 50% от стоимости своих покупок!`
    },
    howToGetBonus: {
        title: `Как получить «морковки»?`,
        promoDescription: `Первая «морковка» <img src=${links.icons.carrotColored} alt="carrot" /> уже ждет тебя.<br>
                       Получи промокод <b>на скидку 7%</b> для первой покупки`,
        nextPoints: `Так, а теперь к делу…`,
        bonusRules: `<b>Оставляйте полезный контент.</b> Пишите отзывы о товарах, которые вы покупаете на apteka24.ua, и получайте от 10 грн на бонусный счет за каждый одобренный отзыв. Кроме того, вы заработаете дополнительную 1 грн за каждый лайк, поставленный под вашим отзывом`,
        reviewRequirements: `Отзывы могут быть как положительными, так и отрицательными. Основное требование к отзыву – максимальная информативность. Чем больше полезной информации для других пользователей будет содержать ваш отзыв, тем больше лайков вы сможете собрать`,
        cashbackRules: `<b>Покупайте на сайте apteka24.ua.</b> За каждую покупку мы возвращаем 2% от её стоимости на ваш бонусный счёт. Например, покупая упаковку лекарства за 310.20 грн, вы получите на бонусный счет целых 6.20 грн! Это как если бы товар стоил вам всего 304 грн – на 15 грн дешевле, чем в другой аптеке*`,
        note: `*Для сравнения взяты цены на "Нимесил гранулы 2 г №30" на 01.03.2021 14:49 на сайтах apteka24.ua и «Первой социальной» аптеки`
    },
    howToSpendBonus: {
        title: `Как потратить «морковки»?`,
        instruction: `Количество накопленных «морковок» вы можете увидеть в верхней части сайта – над окном поиска, рядом с вашей «корзиной». Скидка за «морковки» может применяться совместно с другими скидками, действующими на весь заказ`,
        bonusProducts: `«Морковки» можно тратить на оплату товаров с соответствующим знаком (таких товаров уже больше 3000). А рядом с надписью «Кешбэк» указано, сколько «Морковок» вы получите за покупку`
    },
    promoCode: {
        title: `Промокоды`,
        description: `Промокод – это кодовое слово, дающее право на получение скидки при заказе товара на apteka24.ua. Промокоды – одноразовые и с ограниченным сроком действия. Поэтому, если у вас есть промокод, лучше использовать его сразу`,
        waysToGet: {
            title: `Промокоды можно получить:`,
            social: `Найдя их на наших страницах <a href=${links.facebookA24} target="_blank">Facebook</a> или <a href=${links.instagramA24} target="_blank">Instagram</a>`,
            partners: `На сайтах наших партнёров`,
            promotions: `Участвуя в наших акциях`
        },
        ifCancelOrder: `В случае, если заказ не будет выкуплен, промокод не восстанавливается`
    },
    lawyer: {
        title: `Официальные правила программы лояльности «Морковки»`,
        description: `<p>
                            Участник программы (участник) – физическое лицо, подтвердившее свое намерение
                            участвовать в Программе Лояльности (ПЛ), выполнив требования по вступлению в ПЛ,
                            изложенные в этих правилах ПЛ, а также физическое лицо, изъявившее желание стать
                            участником ПЛ в соответствии с правилами ПЛ, но еще не подтвердившее это намерение
                            выполнением всех требований по вступлению в ПЛ, изложенных в этих правилах ПЛ.
                        </p>
                        <p>
                            Участником ПЛ может стать любое физическое лицо, достигшее 18-летнего возраста и
                            проживающее на территории Украины.
                        </p>
                        <p>
                            Каждый участник программы должен ознакомиться с данными правилами ПЛ и выполнять
                            изложенные в них требования.
                        </p>
                        <p>
                            Участник соглашается с данными правилами, если он совершает любое активное действие
                            на сайте apteka24.ua (например регистрация, покупка, оставление отзыва и т.д.)
                        </p>
                        <p>
                            Персональными данными является информация об участнике, предоставленная самим
                            участником или полученная Аптека24 другим способом, не противоречащим закону,
                            которая позволяет идентифицировать этого участника в рамках ПЛ.
                        </p>
                        <p>
                            Персональные данные участников обрабатываются в соответствии с требованиями Закона
                            Украины «О защите персональных данных» от 01.06.2010 г. №2297.
                        </p>
                        <p>
                            Участник также дает согласие на использование его персональных данных для получения
                            рекламных и специальных предложений, информации о специальных акциях, розыгрышах,
                            иной информации о деятельности Аптека24. Для целей, предусмотренных настоящим
                            пунктом, Аптека24 имеет право направлять письма, сообщения и материалы на
                            электронный почтовый адрес (e-mail) участника, а также отправлять SMS-сообщения,
                            осуществлять звонки на указанный телефонный номер в профиле участника.
                        </p>
                        <p>
                            Аптека24 не несет ответственности за вред или ущерб, нанесенные участнику или
                            третьим лицам в результате ошибочного понимания или непонимания условий данных
                            правил, инструкций или указаний о порядке использования сайта, относительно
                            размещения данных и других технических вопросов.
                        </p>
                        <p>
                            Ответственность Аптека24 по отношению к предоставленным в рамках ПЛ товаров и услуг
                            и соответствующие гарантийные обязательства ограничены требованиями законодательства
                            Украины с учетом особенностей, предусмотренных данными правилами.
                        </p>
                        <p>
                            Ответственность Аптека24 перед участником за нарушение настоящих правил ПЛ
                            ограничивается в случае:
                        </p>
                        <p>
                            • любой претензии, связанной с начислением или не начислением бонусов/морковок;
                        </p>
                        <p>
                            • претензии, связанной с предоставленным товаром, выбранным в качестве привилегии
                            (Аптека24 имеет право заменить данный товар на другой, аналогичный).
                        </p>
                        <p>
                            <strong>
                                Ограничения
                            </strong>
                        </p>
                        <p>
                            Бонусы будут сгорать через 180 дней от момента, когда у пользователя появится
                            возможность их тратить.
                        </p>`
    }
};
