/* eslint-disable max-len */

import links from "app/core/resource/links";

export default {
    pharmacyDonatesMoney: `apteka24.ua дарує 250 000 грн співробітникам на купівлю ліків`,
    leaveYourPhone: `Залиште свій номер до старту програми і отримаєте <span class="f-weight-5">від 100 до 1 000 грн</span> на оплату ліків!`,
    discountForEmployees: `Знижка на ліки для співробітників`,
    invite: {
        from: `Запрошує вас в <strong> програму лояльності </strong> від сервісу apteka24.ua`,
        success: {
            description: "Після перевірки ми нарахуємо Вам бонуси, випадково вибравши 100, 250, 500 або 1000 грн. Їх відразу можна буде витратити на apteka24.ua"
        }
    },
    advantages: {
        cashback: `<b>Кешбек 5%</b> на всі замовлення`,
        bonusForFriend: `<b>75 грн</b> за реєстрацію`,
        freeDelivery: `Завжди <b>безкоштовна</b> доставка`
    },
    infoBlock: {
        employeeCapabilities: `У Вас, як у співробітника найбільшого фарм-дистриб'ютора в Україні, з'являється 
        можливість купувати ліки зі знижкою та на особливих умовах. Замовляючи ліки на apteka24.ua, Ви будете повертати 
        5% від їх вартості на свій бонусний рахунок. Бонуси можна витрачати на оплату майбутніх замовлень для себе, 
        друзів або близьких.`,
        deliveryTerms: `Доставка замовлень на суму від 100 грн буде для Вас завжди безкоштовною. Ми доставляємо 
        замовлення по всій Україні: в 1000 аптек, власними кур'єрами, «Новою поштою» і «Укрпоштою». У Дніпрі доступна 
        доставка за 2 години, а коли ця послуга прийде в інші міста — Ви будете першими, хто зможе нею скористатися.`
    },
    howItWorks: {
        description: `Усі бонуси, які Ви отримуєте як учасник програми, можна витрачати на оплату товарів з «морквинкою»
         на apteka24.ua. Таких товарів зараз більше 3000, і ми щодня працюємо над збільшенням їх кількості, укладаючи 
         договори з виробниками ліків. Отримуючи гроші від виробників, ми передаємо їх у вигляді знижок і бонусів нашим 
         покупцям. Ця проста схема дозволяє нам бути однією з найвигідніших аптек в Україні.`,
        example: `Наприклад, Ви замовляєте ліки на суму 350 грн. З урахуванням бонусів, які Ви отримали після реєстрації
         (скажімо, 100 грн), замовлення буде коштувати Вам 250 грн. Додайте сюди безкоштовну доставку і 5% кешбек, який 
         Ви отримаєте за замовлення — ось Ви і заощадили ще 67,50 грн. За кожен відгук про придбані товари ми платимо ще
          від 10 грн і по 1 грн за кожен лайк до Ваших відгуків. Так, зробивши покупку всього на 350 грн, Ви заощадили 
          29% і отримали ще 100-300 грн бонусів на оплату майбутніх замовлень.`,
        details: `Детальніше про програму для клієнтів і про те як ще заробляти бонуси читайте на 
        <a href=${links.morkovki} target="_blank" class="">цій сторінці</a>.`
    },
    wantMoreBenefits: {
        terms: `Чим довше Ви залишаєтеся учасником програми — тим більше переваг отримуєте. Так, із запуском нових 
        послуг Ви будете першими отримувати до них доступ, а нові знижки завжди будуть для Вас вищими, ніж для інших 
        клієнтів. Але нам потрібна Ваша допомога.`,
        tasks: `Команда apteka24.ua часто запускає нові продукти і сервіси, якість яких ми будемо просити Вас 
        перевірити. Це будуть маленькі щомісячні завдання, виконуючи які Ви зможете залишатися учасником програми 
        і навіть заробляти додаткові бонуси щомісяця, інформуючи нас, якщо щось буде працювати погано.`
    },
    examplesOfTasks: {
        first: `Наприклад, одним з перших нововведень, які зможуть протестувати співробітники, буде акція «Приведи
        друга», а завданням для учасників програми буде привести трьох друзів і отримати за це 150 грн бонусів.`,
        second: `Коли ми запустимо доставку за 2 години у Вашому місті — ми попросимо Вас допомогти нам протестувати і її, розповівши, 
        що ми могли б поліпшити. Скоро ми запустимо пошук ліків по штрих-коду, а пізніше — доставку ліків за 
        передплатою.`,
        rightNowYouCan: {
            task1: `Перевірити, чи зручно залишати відгук про товар – <b>30 бонусів</b>`,
            task2: `Написати відгук, який збере 20 лайків – <b>20 бонусів</b>`,
            task3: `Поставити 5 лайків до чужих відгуків – <b>5 бонусів</b>`
        }
    },
    partners: {
        moreThanThousandProducts: `<span>...і тисячі<br /> інших товарів</span>`
    },
    launchingSoon: `Запуск скоро`
};
