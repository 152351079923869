export default {
    plural: {
        review: ["відгук", "відгука", "відгуків"],
        answers: ["відповідь", "відповіді", "відповідей"],
        days: ["день", "дні", "днів"],
        hours: ["година", "години", "годин"],
        minutes: ["хвилина", "хвилини", "хвилин"],
        seconds: ["секунда", "секунди", "секунд"],
        products: ["товар", "товари", "товарів"],
        coupons: ["купон", "купона", "купонів"]
    },
    errors: {
        server: {
            default: {
                title: "Ой, щось пішло не так",
                description: "Будь ласка, спробуйте ще раз через 30 секунд"
            }
        },
        delivery: {
            notAvailable: "У цьому випадку немає доступних способів доставки обраних товарів у цьому місті"
        }
    },
    calendar: {
        months: ["Січня", "Лютого", "Березня", "Квітня", "Травня", "Червня", "Липня", "Серпня", "Вересня", "Жовтня", "Листопада", "Грудня"]
    },
    review: {
        about: "Відгуки про {0}",
        useful: "Корисний відгук",
        useless: "Некорисний відгук",
        reviewUseful: "Чи є відгук корисним",
        comment: {
            commentUseful: "Чи є коментар корисним",
            useful: "Корисний коментар",
            useless: "Некорисний коментар"
        },
        buyersReviews: "Відгуки покупців",
        viewAll: "Дивитися всі відгуки",
        create: "Залишити відгук",
        loginAndCreate: "Увійти та залишити відгук",
        add: "Доповнити сторінку відгуками",
        aboutProduct: "Відгук про товар",
        sendAnswer: "Надіслати відповідь",
        youHaveNotLeftAnyProductReviewsYet: "Ви ще не залишали відгуки про товари",
        createYourFirstOrderToLeaveAReview: "Саме час створити перше замовлення, щоб залишити відгук",
        messages: {
            receivedReview: "Отримали Ваш відгук",
            receivedAnswer: "Отримали Вашу відповідь"
        },
        allReviews: "Всі відгуки",
        firstReviewIsTheMostValuable: "Перший відгук - найцінніший",
        reviewIsTheMostValuable: "Ваш відгук дуже цінний для нас",
        youHaveAlreadyPurchasedThisItem: "Ви вже купували цей товар",
        dontShowMyName: "Не вказувати моє ім'я",
        write: "Написати відгук"
    },
    myOrders: {
        title: "Мої замовлення",
        youHaveNoOrders: "Ех, жодного замовлення… зате дивіться, що тут є",
        promoCodeForTheFirstOrder: "Промокод на -5% на перше замовлення",
        letIsOrderSomethingForAHomeFirstAidKit: "Ну що, замовимо щось для домашньої аптечки",
        justWriteDrugNameInTheSearch: "Просто напишіть у пошуку назву препарату"
    },
    validation: {
        enterYourRating: "Вкажіть Вашу оцінку",
        enterCorrectPhoneNumber: "Вкажіть коректний номер телефону",
        enterCorrectSymbolsForRuOrUkLanguage: "Дозволяються символи російського і українського алфавіту",
        enterCorrectEmail: "Вкажіть коректну email-адресу",
        enterCorrectFlatNumber: "Вкажіть коректний номер квартири",
        mustBeCompleted: "Обов'язково має бути заповнено",
        errorAvatarUpload: "Фото профілю не завантажено. Потрібна картинка в форматі jpeg, png або gif, розміром до 10 МБ.",
        errorUploadUnpackingPhoto: "Неможливо завантажити фото! Завантаження фото заборонена по одній з наступних причин: невірний формат зображення (допустимі формати jpg, png); розмір фото більше необхідного (понад 15 мб); кількість фото перевищує допустимий (10 зображень); фотографія розпізнана як нецензурного характеру."
    },
    oh: "Ой",
    errorNotificationOrder: "На даний момент неможливо оформити замовлення. Будь ласка, спробуйте ще раз пізніше.",
    youHaveAlreadyApplied: "Ви вже подавали заявку",
    pageNotFound: "Сторінку не знайдено",
    allAboutProduct: "Все про товар",
    allRoundView: "Круговий огляд для",
    analogs: "Аналоги",
    analogsAndSubstitutes: "Аналоги і замінники",
    analogsAndSubstitutesFor: "Аналоги і замінники для {0}",
    analogsFromOtherVendors: "Аналоги від інших виробників",
    youMayAlsoBeInterestedIn: "Також вас можуть зацікавити",
    seeAllAnalogs: "Дивитися всі аналоги",
    viewAll: "Переглянути все",
    view: "Переглянути",
    all: "Все",
    morkovki: "Морквинки",
    favorites: "Обране",
    anotherUser: "Інший користувач",
    personalAccount: "Особистий кабінет",
    privateData: "Персональні дані",
    personalData: "Персональна інформація",
    productCatalog: "Каталог товарів",
    viewedProducts: "Переглянуті товари",
    attentionToThisItems: "Зверніть увагу",
    bestsellers: "Хіти продажів",
    popularBrands: "Популярні бренди",
    lastReviews: "Останні відгуки",
    usefulArticles: "Корисні статті",
    reviews: "Відгуки",
    vacancies: "Вакансії",
    contacts: "Контакти",
    contactDetails: "Контактна інформація",
    deliveryType: "Спосіб доставки",
    deliveryPriceLimitCourier: "Доставка кур'єром доступна при замовленні на суму від",
    choiceDeliveryType: "Вибір способу доставки",
    chooseDepartment: "Виберіть відділення",
    chooseDrugstore: "Виберіть аптеку",
    choicePaymentType: "Вибір способу оплати",
    myReviews: "Мої відгуки",
    myBonuses: "Мої бонуси",
    carrotProgram: "Програма лояльності «Морквинки»",
    bonuses: "Бонуси",
    updateBonuses: "Обновити бонуси",
    useBonuses: "Використати бонуси",
    wantToSpendOnThisOrder: "Хочете витратити на це замовлення",
    amountOfBonusWithoutValue: "Бонусних",
    howToSpendBonuses: "Як витрачати бонуси",
    orderHistory: "Історія замовлення",
    trackYourOrder: "Відстежити",
    shareRules: "Правила акції",
    orderNumber: "Номер замовлення",
    onYourBalance: "На Вашому рахунку",
    wishList: {
        title: "Список бажань",
        emptyList: "Ваш список бажань пустий",
        addProducts: "Додайте до нього товари"
    },
    next: "Далі",
    back: "Назад",
    exit: "Вихід",
    registration: "Зареєструватись",
    joinUs: "Приєднуйтесь",
    clearlyUnderstood: "Зрозуміло",
    pity: "Шкода",
    hotline: "Гаряча лінія",
    phone: "Телефон",
    phoneNumber: "Номер телефону",
    sentSMSWithCode: "На вказаний номер телефону було відправлено смс з паролем",
    enterCode: "Введіть код",
    enterCodeFromSMSOrViber: "Введіть код перевірки з SMS чи Viber",
    sendCodeAgain: "Вислати код ще раз",
    enterSettlementName: "Введіть найменування населеного пункту",
    enterAddressOrDepartmentNumber: "Введіть адресу або номер відділення",
    availableThrough: "Доступно через",
    mondSund: "пн-нд",
    userAgreement: {
        title: "Угода користувача",
        acceptTerms: "Продовжуючи, Ви приймаєте умови",
        confirmOrder: "Підтверджуючи замовлення, я приймаю умови",
        agreement: "Угоди користувача"
    },
    paymentTypes: {
        cash: "Готівкою",
        cashOnDelivery: "Накладений платіж",
        creditCardOnSite: "Оплата картою на сайті"
    },
    deliveryTypes: {
        courier: "Кур'єр за вашою адресою",
        selfDelivery: "Отримання замовлення в аптеці",
        departmentNovaPoshta: "«Нова Пошта»: до відділення",
        courierFromNovaPoshta: "«Нова Пошта»: за вказаною адресою",
        departmentUkrPoshta: "«Укрпошта»: до відділення",
        courierFromUrkPoshta: "«Укрпошта»: за вказаною адресою",
        departmentJustIn: "«Justin»: до відділення",
        departmentMeest: "«Meest»: доставка у відділення",
        courierFromMeest: "«Meest»: доставка кур'єром",
        departmentiPOST: "iPOST: до відділення",
        courierFromiPOST: "iPOST: доставка кур'єром",
        courierFromUklon: "Uklon: доставка кур'єром",
        withoutDelivery: "Без доставки"
    },
    propertiesOfDrug: "Властивості препарату",
    instructionForUse: "Інструкція для застосування",
    instruction: "Інструкція",
    instructionFromVendor: "Інструкція виробника",
    description: "Опис",
    videoReview: "Відеоогляд",
    loginOrRegistration: "Вхід/Реєстрація",
    loginToYourAccount: "Увійдіть в особистий кабінет",
    logIn: "Увійти",
    promotions: "Акції",
    promotionalProducts: "Товари акції",
    brands: "Бренди",
    searchDrug: "Пошук препарату",
    searchByBrand: "Пошук за брендом",
    searchByActiveIngredient: "Пошук за діючою речовиною",
    insuranceCovid: "Страховка від COVID-19",
    searchDrugsByActiveIngredient: "Пошук ліків за діючою речовиною",
    selectionDrugsByActiveIngredientOnline: "Підбір препаратів за діючою речовиною онлайн",
    drugstores: "Аптеки",
    partners: "Партнери",
    ourPartners: "Наші партнери",
    ourTeam: "Наша команда",
    deliveryInformation: "Інформація про доставку",
    deliveryAndPayment: "Доставка та оплата",
    qualityAssurance: "Гарантії якості",
    returnConditions: "Умови повернення",
    whatMakesUsDifferent: "Чим ми відрізняємося",
    aboutUs: "Про нас",
    blog: "Блог",
    covid: "Covid-19",
    bonusesByReviews: "Бонуси за відгуки",
    notField: "Не вказано",
    hideText: "Приховати текст",
    readMore: "Читати далі",
    readAnother: "Читати ще",
    readAll: "Читати все",
    readAllAnswers: "Читати всі відповіді",
    readCompletely: "Читати повністю",
    readArticle: "Читати статтю",
    save: "Зберегти",
    cancel: "Відміна",
    edit: "Редагувати",
    editOrder: "Редагувати замовлення",
    toAnswer: "Відповісти",
    name: "Ім'я",
    lastName: "Прізвище",
    middleName: "По батькові",
    birthday: "Дата народження",
    gender: "Стать",
    communicationLanguage: "Мова спілкування з apteka24.ua",
    languageSelection: "Вибір мови",
    about: "Про себе",
    ru: "Російська",
    uk: "Українська",
    male: "Чоловіча",
    female: "Жіноча",
    profilePhoto: "Фото профілю",
    change: "Змінити",
    show: {
        default: "Показати",
        more: "Показати ще",
        all: "Показати все",
        less: "Приховати"
    },
    informationAboutProduct: "Інформація про товар",
    informationAboutOrder: "Інформація про замовлення",
    informationAboutCompany: "Інформація про компанію",
    orderPrice: "Сума замовлення",
    warningOrderPriceChange: "Звернить увагу сума замовлення змінилася",
    orderDate: "Дата замовлення",
    productsPrice: "Вартість товарів",
    sendOrder: "Відправити замовлення",
    deliveryPrice: "Сума доставки",
    quantity: "Кількість",
    or: "Або",
    from: "Від",
    amount: "Сума",
    forAmount: "На суму",
    payment: "Оплата",
    paymentInformation: "Інформація про сплату",
    paymentType: "Спосіб оплати",
    total: "Разом",
    changeOrderToPayment: "Зміна вартості замовлення",
    totalForPayment: "Разом до оплати",
    checkout: "Оформити замовлення",
    orderCreation: "Оформлення замовлення",
    orderDetails: "Деталі замовлення",
    orderItems: "Замовлені товари",
    findDrugs: "Знайти ліки",
    learnMore: "Дізнатись більше",
    send: "Відправити",
    moreDetails: "Детальніше",
    moreDetailsAboutBonuses: "Докладніше про бонуси",
    sort: "Сортувати",
    reset: "Скинути",
    clear: "Очистити",
    clearList: "Очистити список",
    yourName: "Ваше ім'я",
    yourLastName: "Ваше прізвище",
    yourEmail: "Ваш e-mail",
    yourPhone: "Ваш телефон",
    yourMessage: "Ваше повідомлення",
    yourComment: "Ваш коментар",
    frequentlyAskedQuestions: "Поширені запитання",
    didWeAnswerYourQuestion: "Ми відповіли на Ваші питання?",
    ifNotLetUsKnow: "Якщо ні, будь ласка, дайте нам знати",
    notifyOfRepliesByEmail: "Повідомляти про відповіді по електронній пошті",
    cities: "Міста",
    pharmaciesForIssue: "Аптеки для видачі",
    drugsDelivered: "Ліків доставлено",
    customers: "Покупці",
    howWeWork: "Як ми працюємо",
    discount100UAH: "Знижка 100 грн",
    regularCustomer: "Я постійний клієнт",
    recipient: "Одержувач",
    recipientIsADifferentPerson: "Одержувач інша людина",
    freeChatWithPharmacist: "Безкоштовний чат з досвідченим фармацевтом",
    doctorsAboutProject: "Лікарі про проект",
    recommend: "Рекомендовані",
    recommendedForPurchase: "Рекомендуємо до покупки",
    continueShopping: "Продовжити покупки",
    backToShopping: "Повернутися до покупок",
    ascendingPrice: "За зростанням ціни",
    descendingPrice: "За зменшенням ціни",
    youChoose: "Ви обрали",
    warning: "Увага",
    vendorCard: {
        contacts: {
            mainOffice: "Головний офіс",
            phone: "Телефон",
            email: "e-mail",
            fax: "Факс",
            site: "Сайт"
        },
        aboutCompany: "Про компанію ",
        johnson: {
            name: "ТОВ «Джонсон і Джонсон Україна»"
        },
        farkos: {
            name: "Фармацевтична компанія «ФарКоС»"
        }
    },
    buyInOneClick: "Купити в один клік",
    buy: "Купити",
    alreadyBought: "Вже купив(ла)",
    reviewByMobileApp: "Відгук залишено з мобільного додатку",
    inBasket: "В кошику",
    basket: "Кошик",
    addToBasket: "Замовити",
    toBasket: "Перейти в кошик",
    toBuyer: "Покупцеві",
    company: "Компанія",
    companyRepresentative: "Представник компанії",
    officialRepresentativeOfApteka24: "Офіційний представник Аптека24",
    toPartners: "Партнерам",
    weAreInSocials: "Ми в соціальних мережах",
    socialNetworks: "Соціальні мережі",
    massMediaAboutUs: "ЗМІ про нас",
    doctorsAboutTheService: "Лікарі про сервіс",
    vitaminsAndMinerals: "Вітаміни та мінерали",
    popularProducts: "Популярні товари",
    antibiotics: "Антибіотики",
    gynecologicalPreparations: "Гінекологічні препарати",
    urologicalPreparations: "Урологічні препарати",
    links: "Посилання",
    logoutFromAccount: "Вийти з облікового запису",
    openOriginalImage: "Відкрити велике зображення",
    filter: "Фільтр",
    filters: "Фільтри",
    activeIngredient: "Активний інгредієнт",
    seller: "Продавець",
    sellerPrice: "ціна",
    sellerOfProduct: "Продавець товару",
    categories: "Категорії",
    allCategories: "Всі категорії",
    since: "з",
    price: "Ціна",
    priceFor: "Ціна на",
    prices: "Ціни",
    emptySearchResult: "Нічого не знайдено, спробуйте змінити запит",
    youMeant: "Можливо, ви мали на увазі",
    selectedAll: "Вибрати все",
    select: "Виберіть",
    apply: "Застосувати",
    accept: "Прийняти",
    removeSelection: "Скинути вибір",
    delete: "Видалити",
    toHomePage: "Перейти на головну",
    mobileApp: "Мобільний додаток",
    informAboutAvailability: "Повідомити, коли з'явиться",
    willInformAboutAvailability: "Повідомимо про наявність",
    legitimnost: "Легітимність",
    ourMission: "Наша місія",
    ourAwards: "Наші нагороди",
    ourAdvantages: "Наші переваги",
    socialPrograms: "Соціальні програми",
    medicalAnswers: "Медичні відповіді",
    medicalExperts: "Медичні експерти",
    cooperationProposals: "Пропозиції про співпрацю",
    partnerAccount: "Особистий кабінет партнера",
    forPregnantAndBreastFeeders: "Вагітним та жінкам, які годують груддю",
    duringPregnancyAndBreastfeeding: "При вагітності та грудному вигодовуванні",
    howToOrderDrugs: "Як замовити ліки",
    editorialPolicy: "Редакційна політика",
    reviewsPostingPolicy: "Політика публікації відгуків",
    marketingPolicy: "Маркетингова політика",
    showMoreInstruction: "Переглянути всю інструкцію",
    showMoreDescription: "Переглянути весь опис",
    showMoreProperties: "Дивитися всі характеристики",
    showMoreHistory: "Дивитися всю історію",
    found: "Знайдено",
    popularCategories: "Популярні категорії",
    free: "Безкоштовно",
    freeOverUkraine: "Безкоштовно по Україні",
    deliveryCompany: "Транспортна компанія",
    orderUpTo: "Замовлення до",
    orderFrom: "Замовлення від",
    currency: {
        uah: "грн"
    },
    orders: "Замовлення",
    deliveryTo: "Доставка в",
    aboutDeliveryLocations: "Ми возимо замовлення в усі населені пункти України",
    enterCityNameOrVillage: "Вкажіть потрібне Вам місто або селище, щоб побачити доступні способи доставки",
    cashbackForPurchases: "Кешбек за покупки",
    discountPromoCodes: "Промокоди на знижки",
    promoCode: "Промокод",
    supportGroupAndPharmacist: "Служба підтримки та зв'язок з фармацевтом",
    giftCoupons: "Подарункові купони",
    referralProgram: "Реферальна програма",
    freeDelivery: "Безкоштовна доставка",
    drugBenefits: "Переваги препарату",
    privacyPolicy: "Політика конфіденційності",
    download: "Завантажити",
    downloadApp: "Завантажуйте додаток",
    cityNames: {
        kiev: "Київ",
        kharkov: "Харків",
        dnepr: "Дніпро",
        odessa: "Одеса",
        rovno: "Рівне",
        vinnitsa: "Вінниця",
        zaporozhe: "Запоріжжя",
        ivanoFrankovsk: "Івано-Франківськ",
        kramatorsk: "Краматорськ",
        kremenchug: "Кременчуг",
        krivoyRog: "Кривий Ріг",
        lvov: "Львів",
        nikolaev: "Миколаїв",
        poltava: "Полтава",
        sumy: "Суми",
        ternopol: "Тернопіль",
        kherson: "Херсон",
        cherkassy: "Черкаси",
        chernovtsy: "Чернівці",
        chernigov: "Чернігів"
    },
    createApplication: "Залишити заявку",
    validity: "Термін дії",
    filling: "Наповнення",
    conditions: "Умови",
    priceFrom: "Цiна вiд",
    priceTo: "Цiна до",
    deliveryTime: "Час доставки",
    workTime: "Графік роботи",
    supportService: "Служба підтримки",
    warranty: "Гарантія",
    code: "Код",
    productCode: "Код товару",
    genericDrug: "Препарат дженерик",
    generic: "Дженерик",
    thankYouForTheOrder: "Дякуємо за замовлення",
    yourOrderHasBeenCompleted: "Ми прийняли Ваше замовлення",
    checkTheAvailabilityOfAllProducts: "Перевіряємо наявність всіх товарів",
    ourManagerWillContactYouShortly: "Наш менеджер зв'яжеться з вами незабаром",
    ourPharmacistWillContactYouForConsultation: "Наш фармацевт зателефонує вам найближчим часом для узгодження замовлення. Через кілька хвилин ми надішлемо підтвердження та номер вашого замовлення в sms або viber",
    ourPharmacistWillContactYouIfNecessary: "Ваше замовлення буде опрацьоване автоматично, у разі потреби з вами зв'яжеться фармацевт. Через кілька хвилин ми надішлемо підтвердження та номер вашого замовлення в sms або viber",
    iNeedAPharmacistConsultationOnMyOrder: "Мені потрібна консультація фармацевта на моє замовлення",
    thank: "Дякуємо",
    callYouToClarifyOrderDetails: "Ось-ось зателефонуємо Вам, щоб уточнити деталі",
    chooseYourCity: "Виберіть своє місто",
    vendor: "Виробник",
    countryOfManufacture: "Країна-виробник",
    doctorsAnswersYourQuestions: "Відповіді лікарів на Ваші питання",
    officialAnswer: "Офіційна відповідь",
    sources: "Джерела",
    similarQuestions: "Подібні запитання",
    amountInTheCatalog: "{0} шт. у каталозі",
    amountOfThingsYouNeedToKnow: "{0} речей, які вам потрібно знати",
    amountOfBonusMoney: "{0} бонусних гривень",
    recommendations: "Рекомендації",
    editorialStaff: "Редакція",
    censor: "Цензор",
    updated: "Оновлено",
    created: "Створено",
    verified: "Перевірено",
    yourDataIsBeingVerified: "Ваші дані на перевірці",
    home: "Головна",
    notAvailable: "Немає в наявності",
    setRate: "Поставте оцінку",
    allSearchResults: "Всі результати пошуку",
    searchHistory: "Історія пошуку",
    youMayBeLookingFor: "Можливо, Ви шукаєте",
    priceAndAvailabilityAreRelevantTo: "Ціна і наявність актуальні на",
    certificate: "Сертифікат",
    allArticles: "Всі статті",
    redaction: "Редакція",
    bonusesUsed: "Використано бонусів",
    redactionFrom: "Редакція від",
    historyBonusTransactions: "Історія нарахування та списання бонусів",
    youDontHaveAnyBonusesYet: "У Вас поки немає бонусів",
    accumulateBonuses: "Накопичуйте бонусні гривні і використовуйте їх при оплаті своїх покупок",
    pricesFor: "Ціни на",
    cashback: "Кешбек",
    returnToBonusAccount: "Повернеться на бонусний рахунок",
    informed: "Ознайомлений",
    carefully: "З обережністю",
    today: "Сьогодні",
    tomorrow: "Доставимо завтра",
    street: "Вулиця",
    house: "Будинок",
    flat: "Квартира",
    lookAt: "Переглянути",
    address: "Адреса",
    enterAddress: "Вкажіть адресу",
    whereLookingForEnterAddress: "Де шукати? Введіть адресу тут",
    searchByAddressOrDrugstoreName: "Пошук за адресою чи по назві мережі",
    drugstoresList: "Список аптек",
    yourCity: "Ваше місто",
    asList: "Списком",
    asMap: "На мапі",
    allCities: "Усі міста",
    showDrugstoresByCity: "Відобразити аптеки по населеному пункту",
    showMoreCities: "Ще {0} міст",
    severalDrugstores: "Декілька аптек",
    partnerDrugstores: "Партнерські аптеки",
    selfDeliveryPoint: "Пункт самовивозу",
    freeSelfDeliveryFromDrugstores: "Безкоштовний самовивіз з аптек",
    drugstoreAddress: "Адреса аптеки",
    howToGetThere: "Як дістатися",
    seeOnTheMap: "Дивитися на мапі",
    searchRadius: "Радіус пошуку",
    howPlaceOrderToDrugstore: "Як зробити замовлення у цю аптеку",
    findAllDrugsYouNeed: "Знайти усі необхідні препарати на нашому сайті",
    addToCartAndStartCheckout: "Додати їх у кошик та почати оформляти замовлення",
    chooseDrugstoreInDeliveryMethods: "Обрати цю аптеку у способах доставки",
    orYouCanPlaceAnOrderByPhone: "Або можна оформити замовлення за телефоном",
    codeCopied: "Код скопійований",
    toShopping: "За покупками",
    wantMoreBenefits: "Вау, хочу більше переваг",
    examplesOfTasks: "Приклади завдань",
    rightNowYouCan: "А ось прямо зараз Ви можете",
    registeredEmployees: "Зареєстровано співробітників",
    bonusesAccrued: "Бонусів нараховано",
    giftsLeft: "Залишилося подарунків",
    restOfTime: "Залишилося",
    afterOfTime: "Через",
    sendRequest: "Відправити запит",
    saving: "Економія",
    likes: "Лайки",
    toDefine: "Визначити",
    toDefineAutomatically: "Визначити автоматично",
    watchChannel: "Дивитися канал",
    aboutDoctor: "Про лікаря",
    articles: "Статті",
    expert: "Експерт",
    registrationDate: "Дата реєстрації",
    expertInTopic: "Експерт в темі",
    certificates: "Сертифікати",
    receive: "Отримати",
    fine: "Добре",
    doctorsCertificate: "Сертифікат лікаря",
    addPhoto: "Додати фото",
    addPhotoDescription: "Додавайте до 10 зображень в форматах .jpg, .png, розміром файлу до 15 МБ",
    images: "Зображення",
    usersPhotos: "Фотографії користувачів",
    pcs: "Шт",
    detailedInformation: "Детальна інформація",
    content: "Зміст",
    usersPhoto: "Фотографії покупців",
    seeAllPhotos: "Подивитися всі зображення",
    goToGallery: "Перейти в галерею",
    topNegativeReview: "Кращий негативний відгук",
    topPositiveReview: "Кращий позитивний відгук",
    uploadPhoto: "Завантажити фотографії",
    done: "Готово",
    almostDone: "Майже готово",
    uii: "Уіі",
    letsStart: "Ну що, почнемо",
    changeName: "Змінити ім'я",
    showMore: "Показати ще",
    will: "Буде",
    originalProduct: "Оригінальний препарат",
    fromYou: "Від вас",
    distance: {
        km: "км",
        m: "м"
    },
    takeAway: "Забрати",
    takeAwayToday: "Зберемо за 15 хв",
    please: "Будь ласка",
    toApplyPromoCodeOrViewBonuses: "Для застосування промокодом або перегляду бонусів",
    expandSearchRadius: "Розширте радіус пошуку",
    willDeliver: "Доставимо",
    consultationViaMessengers: "Ви можете звернутися за консультацією фармацевта або з будь-якого іншого питання до нас, використовуючи зручний для Вас месенджер",
    redemptionOfElectronicPrescriptions: "Погашення електронних рецептів",
    salesRules: "Правила здійснення продажу",
    documentsForConfirmationMinimumAge: "Клієнта можуть запитати документи, що підтверджують, що йому 14 років",
    requisites: "Реквізити",
    infoAboutOrderStatusToTheSection: "Щоб отримати інформацію про поточний стан замовлення, перейдіть до розділу",
    repeatOrder: "Повторити замовлення",
    governmentalSupport: "Це замовлення можна сплатити карткою «єПідтримка»!",
    inAppStore: "В App Store",
    inGooglePlay: "В Google Play",
    paymentMethod: "Оплата в аптеці",
    sellerTitle: "Ціна товару залежить від продавця",
    basketTabPricePharmacy: "Всього до сплати"
};
