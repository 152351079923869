/* eslint-disable max-len */

export default {
    whoCan: {
        allowed: `Статус «Разрешено» означает, что данный препарат обладает минимальным риском для здоровья у данной группы населения. Ожидаемая польза от приема превышает возможные риски. Тем не менее, все лекарственные средства мы рекомендуем применять под наблюдением врача. Напоминаем, что самолечение может быть опасным для вашего здоровья.`,
        prohibited: `Статус «Запрещено» означает, что риски при применении таких препаратов всегда превышают ожидаемую пользу для данной группы населения. Мы рекомендуем отказаться от применения данных лекарственных средств.`,
        carefully: `Статус «С осторожностью» означает, что данный препарат обладает повышенным риском для здоровья у данной группы населения. Применение данного лекарства допустимо, если ожидаемая польза от приема будет превышать возможные риски. Применение таких препаратов может осуществляться только по назначению лечащего врача.`,
        noData: `Статус «Нет данных» означает, что достоверная информация об особенностях применения препарата у данной группы населения отсутствует, влияние малоизучено либо доверительные клинические исследования не проводились или проводились в ограниченном количестве, что не позволяет считать полученные данные достоверными.`
    },
    prescription: {
        withPrescription: `Препарат отпускается по рецепту. При получении товара фармацевт может попросить вас показать рецепт. Применение таких средств обязательно должно происходить под контролем врача.`,
        withoutPrescription: `Препарат отпускается без рецепта. Однако мы рекомендуем вам перед применением любых медикаментов, даже безрецептурных, проконсультироваться с врачом во избежание ошибок лечения.`
    },
    alcohol: {
        minimal: `Взаимодействие с алкоголем минимально значимое. Применение препарата совместно с алкоголем нежелательно и требует мониторинга со стороны врача, так как существуетф незначительный риск негативного взаимодействия.`,
        moderate: `Взаимодействие с алкоголем умеренно значимое. Обычно нужно избегать совместного применения данного препарата и алкоголя, так как при взаимодействии риск превышает пользу. Применение допустимо только в редких случаях, минимальных дозах и под контролем врача.`,
        critical: `Взаимодействие с алкоголем критически значимое. Необходимо избегать совместного применения данного препарата и алкоголя, так как при их взаимодействии риск для организма значительно превышает пользу.`,
        noData: `Информация о взаимодействии данного препарата и алкоголя неизвестна, поэтому их совместное применение не рекомендуется во избежание возможных рисков для здоровья.`
    },
    temperature: {
        from5to25: `Хранить данный препарат можно только при комнатной температуре в диапазоне от 5 до 25 °С.`,
        from8to15: `Хранить данный препарат можно только в прохладном месте при температуре от 8 до 15 °С, например, на верхних полках дверцы холодильника.`,
        from2to8: `Хранить данный препарат можно только в холодном месте при температуре от 2 до 8 °С, например, в холодильнике.`,
        fromMinus5toMinus18: `Хранить данный препарат можно только в морозильной камере при температуре от -5 до -18 °С.`,
        below18: `Хранить данный препарат можно только при глубоком замораживании при температуре ниже -18 °С.`
    },
    children: `Препарат можно применять детям с указанного возраста. Перед приемом проконсультируйтесь с врачом. Применение любых лекарств у детей младше 2 лет обязательно по назначению врача.`
};
