/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    title: "«Турбота 24/7» —  ваша финансовая защита при COVID&#8209;19",
    subtitle: `Надежное страхование здоровья в случае заболевания <b>COVID&#8209;19</b> для клиентов apteka24.ua от страховой компании <a target="_blank" href="${links.upsk}" rel="nofollow"><noindex><b>UPSK</b></noindex></a>`,
    program: {
        description: 'Во время пандемии коронавируса, когда еще нет ни вакцины, ни лекарства, в Украине лечение становится длительным и дорогостоящим. Мы считаем, что каждый заболевший человек нуждается в финансовой и медицинской помощи и поддержке',
        proposal: `Поэтому мы предлагаем вам страховую программу <b>«Турбота 24/7 - COVID&#8209;19 Базовый»</b>`
    },
    advantages: {
        title: `Что вы получаете при страховании от COVID&#8209;19?`
    },
    filling: {
        title: `Что входит в страховой пакет от COVID&#8209;19?`,
        description: `Клиенты apteka24.ua могут оформить пакет страхования «Турбота 24/7 - COVID&#8209;19 Базовый», который покрывает следующие страховые случаи:`,
        instruction: `Как только вы заключили договор страхования <b>COVID&#8209;19</b>, услуги страхования вступают в силу. При кашле, высокой температуре и плохом самочувствии вам нужно сразу же <b>позвонить в службу поддержки СК «UPSK»</b> — и с этого момента мы берем на себя заботу о вашем здоровье`,
        conditions: {
            validity: {
                name: `Срок действия:`,
                value: `12 месяцев`
            },
            price: {
                name: `Единоразовый платеж на 1 человека:`,
                value: 'всего 300 грн'
            }
        },
        medicalConcierge: {
            title: `Медицинский консьерж`,
            subtitle: `Любое застрахованное лицо получает онлайн-консультацию квалифицированного врача касательно:`,
            prevention: `Профилактики <b>COVID&#8209;19</b>`,
            psychologicalSupport: 'Психологической поддержки при психоэмоциональном перенапряжении в связи с <b>COVID&#8209;19</b>',
            laboratoryConsultation: `Консультацию по результатам лабораторных исследований касаемо инфицирования застрахованного лица <b>COVID&#8209;19</b> (полученных застрахованным лицом за личный счет)`,
            hospitalConsultation: `Консультацию в медицинском учреждении по поводу диагностики и лечения <b>COVID&#8209;19</b>`
        },
        caseIllness: {
            title: `В случае заболевания застрахованного лица на COVID&#8209;19`,
            subtitle: `Если вы заболели и тест подтвердил у вас коронавирус, вы получаете:`,
            treatmentWithoutResuscitation: `Диагностику и лечение в стационарных условиях осложнений в результате инфицирования <b>«пневмония без реанимации» COVID&#8209;19</b>`,
            treatmentWithResuscitation: `Диагностику и лечение осложнений в стационарных условиях в результате инфицирования <b>«пневмония c реанимацией» COVID&#8209;19</b>`,
            death: `Смерть застрахованного лица в результате <b>COVID&#8209;19</b>`
        }
    },
    terms: {
        title: `Все условия страхования от COVID&#8209;19`
    }
};
