export default {
    pocketPharmacy: {
        title: "Аптека у вас в кармане",
        description: "Скидка до 50% на лекарства, медтовары, косметику и многое другое"
    },
    economy: {
        boldDescriptionLeft: "Установи приложение ",
        descriptionLeft: "и получи скидку 100 грн на первый заказ от 1000 грн по промокоду АРР100",
        boldDescriptionCenter: "Эксклюзивные акции – ",
        descriptionCenter: "скидки до 30% на сотни товаров",
        boldDescriptionRight: "До 50% экономии ",
        descriptionRight: "с выгодной программой лояльности"
    },
    advantage: {
        chatTitle: "Бесплатный чат с опытным фармацевтом",
        chatDescription: "Мы рядом 24/7 для консультаций, подбора лекарств и оформления заказов",
        barcodeTitle: "Поиск лекарств по штрихкоду",
        barcodeDescription: "Наведите камеру телефона на упаковку препарата, чтобы найти его в нашем каталоге",
        dragsTitle: "12 000 препаратов в одном приложении",
        dragsDescription: "Больше, чем в любой аптеке. Включая редкие и дефицитные товары"
    },
    delivery: {
        deliveryTitle: "Удобная доставка: домой или в любимую аптеку",
        deliveryDescription: "Мы доставляем заказы в 1000+ аптек, курьерами домой и службами доставки «Нова пошта» и «Укрпошта» в отделения",
        pickup: `Самовывоз из <br> Аптеки:`,
        courier: "Курьер по <br> вашему адресу",
        post: "«Нова Пошта»: <br> до отделения",
        ukrpost: "«Укрпошта»: <br> до отделения",
        locationTitle: "Работаем по всей Украине",
        locationDescription: "Доставляем заказы в любой населенный пункт — до 1 часа в крупных городах, до 3 дней в посёлки"
    },
    downloadApp: {
        title: "Экономить легче в приложении apteka24.ua"
    }
};
