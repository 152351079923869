/* eslint-disable max-len */
import links from "app/core/resource/links";

import pregnancy from "./pregnancy";
import whoAllowed from "./whoAllowed";
import footer from "./footer";
import warnings from "./warnings";
import property from "./property";
import promoBanners from "./promoBanners";
import license from "./license";
import loyaltyProgram from "./loyaltyProgram";
import about from "./about";
import advantages from "./advantages";
import feedback from "./feedback";
import manufacturer from "./manufacturer";
import home from "./home";
import review from "./review";
import notes from "./notes";
import insurance from "./insurance";
import bonus from "./bonus";
import catalog from "./catalog";
import bonusesAirdrop from "./bonusesAirdrop";
import installApp from "./installApp";
import employeeDiscount from "./employeeDiscount";
import medicalExperts from "./medicalExperts";
import welcomeBonus from "./welcomeBonus";
import weeklyPromotion from "./weeklyPromotion";
import howWeWork from "./howWeWork";
import blogSubscribers from "./blogSubscribers";
import bepanten from "./bepanten";
import mobileApp from "./mobileApp";
import newLoyaltyProgram from "./newLoyaltyProgram";

export default {
    rulesReceivingBonusByReviews: `Ми нараховуємо бонуси за гарні відгуки, написані з дотриманням 
    <a href="${links.reviewsPostingPolicy}" target="_blank">наших правил</a>.
   Якщо Ваш відгук цікаво читати, Ви ділитеся особистим досвідом, якщо Ви чесно розповідаєте про побічні ефекти
     або про марність препарату - це хороший відгук і ми його схвалимо. Бонуси за відгуки можна витрачати на покупки.
     Дізнайтеся <a href="${links.morkovki}" target="_blank">докладніше про це</a>.`,

    bonusByReview: "<strong>+30 бонусів</strong> за відгук",

    bonusesWillBeCreditedForTheOrder: "За покупку буде нараховано <span class='f-weight-5'>{0}</span> бонусів",

    deliveryDrugsFromVendors: `Сервіс для пошуку та замовлення товарів аптечного асортименту зі складу найбільшого фармдистрибьютора в Україні`,

    drugsEconomy: "Працюючи безпосередньо з дистриб'ютором, а також завдяки програмі лояльності, акціям, знижкам і бонусам ми надаємо економію на ліки до <strong>50%</strong>!",

    orderingInstruction: `<p>Коли ви вирішите оформити замовлення, просто знайдіть потрібні ліки на apteka24.ua або
                         зателефонуйте за безкоштовним номером <a href="tel:0800302244">0800 30 22 44</a>.</p>
                        <p>На гарячій лінії apteka24.ua працюють фармацевти, які допоможуть з вибором товару і оформлять
                         замовлення прямо по телефону.</p>
                        <p>Про пересування замовлення ви будете проінформовані по SMS або Viber.</p>`,

    warrantyStages: `Кожен наш товар, починаючи свій життєвий шлях від виробника і закінчуючи
                     прибуттям в аптеку, де Ви його отримуєте, проходить кілька важливих етапів.
                     Купуючи ліки і медичні товари у нас, Ви отримуєте такі гарантії:`,
    bonusesAmount: "<span>{0}</span> бонусних гривень",
    paymentTypes: "Готівкою при отриманні, оплата картою на сайті, накладений платіж",
    warranty: {
        certification: "Весь товар сертифікований",
        fromSeller: "Продавець гарантує упаковку замовленого товару, яка забезпечує його цілісність і збереження належної якості і товарного вигляду"
    },
    temperatureMode: {
        title: "Температурний режим і «холодовий ланцюг»",
        description: "Умови зберігання і транспортування лікарських засобів відповідають оптимальному температурному режиму для кожного препарату. Термолабільние препарати зберігаються і транспортуються з дотриманням «холодового ланцюга» - безперебійно функціонуючої системи температурного режиму, що забезпечує необхідні холодові умови для зберігання і транспортування термолабільних препаратів на всіх етапах шляху їх слідування від підприємства-виготовлювача до аптеки"
    },
    generic: "Препарат дженерик - лікарський засіб, що містить активний фармацевтичний інгредієнт, ідентичний запатентованому початковим розробником ліки. Після закінчення терміну дії патенту формула діючої речовини стає суспільним надбанням і може відтворюватися іншими компаніями під міжнародними непатентованими найменуванням або під патентованим назвою, що відрізняється від початкового. Препарат дженерик відрізняється від оригіналу тільки складом допоміжних речовин. Діючі речовини, їх дозування, фармакологічна дія і спосіб застосування нічим не відрізняються. У більшості випадків препарат дженерик є взаємозамінним і продається за нижчою ціною, ніж брендовий синонім.",
    buyInOneClick: {
        instruction: "Менеджер зв'яжеться з Вами, дізнається всі деталі і сам оформить замовлення на Ваше ім'я"
    },
    cookieAgreement: {
        description: `Сайт використовує файли "cookie" для створення персонального контенту`
    },
    seo: {
        productCard: {
            aboutDeliveryLocation: `
                Ціна на {0} актуальна при замовленні на сайті. На apteka24.ua можна купити
                {1} з доставкою в такі
                міста України: Київ, Харків, Дніпро, Одеса, Рівне, Біла Церква, Вінниця, Запоріжжя, Івано-Франківськ,
                Краматорськ, Кременчук, Кривий Ріг, Кропивницький, Львів, Луцьк, Маріуполь, Миколаїв, Полтава, Суми, Тернопіль,
                Херсон, Житомир, Хмельницький, Черкаси, Чернівці, Чернігів. В інші міста замовлення можуть доставлятися через
                службу доставки. Доступна доставка кур'єром.
                <a href="${links.delivery}" target="_blank">Докладніше</a> про способи, вартості та
                обмеженнях доставки.
            `
        }
    },
    telegram: {
        title: `Приєднуйтесь до нас в `,
        name: `Telegram`,
        description: "Тут ми розповідаємо маловідомі факти про здоров'я. Пропонуємо кращі методики лікування. Публікуємо статті авторитетних лікарів. Ділимося  лайфхаками. Шукайте нас в Telegram — @apteka24ua"
    },
    pharmacistConsultation: {
        title: `Безкоштовний чат з досвідченим фармацевтом`,
        description: `<p>
                          Опишіть симптоми або потрібний препарат — ми допоможемо підібрати його дозування чи аналог, оформимо замовлення з доставкою додому або просто проконсультуємо. Ми — це 28 фармацевтів і 0 ботів.
                      </p>
                      <p>
                          Так ми завжди будемо з вами на зв'язку і зможемо поспілкуватися в будь-який момент.
                      </p>`,
        workingTime: `Онлайн: 08:00 - 21:00, без&#160вихідних`
    },
    discountConditions: {
        about: `Нагадуємо, що у <span className="font-weight-700">apteka24.ua</span> є мобільний додаток: 12 000 препаратів з докладними інструкціями і, звичайно, доставкою по всій Україні. Шукайте нас в <a href="${links.a24AppStore}">App Store</a> і <a href="${links.a24GooglePlay}">Google Play</a>.`,
        conditions: `P.S. <span className="font-weight-700">Знижка 100 грн</span> на перше замовлення від 1000 грн за промокодом <span className="font-weight-700">APP100</span>`
    },
    generalMedicalQuestions: "Загальні медичні питання, що стосуються ліків і захворювань, на які відповідає команда кваліфікованих фахівців в області охорони здоров'я",
    aboutSearchDrugsByActiveIngredient: "У нашій аптеці також знайдете товари, вибравши з переліку медикаменти за діючою речовиною. Даний пошук зручний тим, що навіть не знаючи конкретну назву медпрепарату, можна з легкістю підібрати для себе велику кількість ліків, у складі яких зазначено шукане речовина",
    aboutSortingByActiveIngredient: "Так як і за допомогою інших зручних варіантів пошуку на нашому сайті, сортування за діючою речовиною допоможе знайти відразу кілька товарів, порівняти їх ціни, перевірити наявність або ж підібрати аналоги",
    aboutMethodsSearchingDrugs: "На сайті Аптека24 є й інші способи пошуку медикаменту: список товарів в алфавітному порядку і пошук по бренду",
    whatIsAnalog: "Повний збіг складу діючих речовин, їх дозування і форми випуску",
    notes,
    pregnancy,
    whoAllowed,
    footer,
    warnings,
    promoBanners,
    license,
    loyaltyProgram,
    about,
    feedback,
    manufacturer,
    home,
    review,
    property,
    advantages,
    bonus,
    catalog,
    insurance,
    bonusesAirdrop,
    installApp,
    employeeDiscount,
    medicalExperts,
    welcomeBonus,
    weeklyPromotion,
    howWeWork,
    blogSubscribers,
    bepanten,
    mobileApp,
    newLoyaltyProgram
};
