import Enum from "app/core/utilites/enum/Enum";

let contacts = new Enum({
    fields: [
        {key: "phone", value: "0800 30 22 44"}
    ]
});

export default {
    getInstance() {
        return contacts;
    }
};
