/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    morkovkiBeta: `Морквинки`,
    workTime: `з 8:00 до 20:00 без вихідних, <br /> безкоштовно в межах України!`,
    selfMedication: "Самолікування може бути шкідливим для Вашого здоров'я",
    consult: "Перед застосуванням препарату проконсультуйтесь з лікарем",
    copyright: `© 2013-2024 ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «МІС-Cервіс». Інформація, розміщена на електронній медичній інформаційній системі apteka24.ua, призначена виключно для ознайомлювальних цілей. Не використовуйте інформацію з цього сайту для діагностики, лікування або профілактики захворювань. Постановка діагнозу і вибір методики лікування здійснюється тільки вашим лікарем! <a href=${links.responsibility}>Детальніше</a> про відмову від відповідальності.`,
    educationalPartner: `Наш
                        <br /> освітній
                        <br /> партнер`,
    medicalPartner: `Наш
                    <br /> медичний
                    <br /> партнер`
};
