/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    morkovkiBeta: `Морковки`,
    workTime: `с 8:00 до 20:00 без выходных, <br /> бесплатно в пределах Украины!`,
    selfMedication: "Самолікування може бути шкідливим для Вашого здоров'я",
    consult: "Перед застосуванням препарату проконсультуйтесь з лікарем",
    copyright: `© 2013-2024 ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «МИС-Cервис». Информация, размещенная на электронной медицинской информационной системе apteka24.ua, предназначена исключительно для ознакомительных целей. Не используйте информацию с этого сайта для диагностики, лечения или профилактики заболеваний. Постановка диагноза и выбор методики лечения осуществляется только вашим лечащим врачом! <a href=${links.responsibility}>Подробнее</a> об отказе от ответственности.`,
    educationalPartner: `Наш
                        <br /> образовательный
                        <br /> партнер`,
    medicalPartner: `Наш
                    <br /> медицинский
                    <br /> партнер`
};
