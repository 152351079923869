/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    email: {
        title: `<span class="f-weight-7">Підписчикам блогу</span>`,
        description: `Знижка <span class="">50 грн </span>на наступне замовлення в apteka24`
    },
    emailVerified: {
        title: `<span class="d-flex align-items-center justify-content-center">Ваш промокод активований 
        <img src=${links.images.employeeDiscount.congratulations} class="ml-8" alt="emodji" /></span>`,
        description: `Замовляйте ліки з доставкою <br /> по всій Україні зі знижкою!`
    },
    bonusForEmail: {
        title: `<span class="d-flex align-items-center justify-content-center">Ви – краще за всіх <img src=${links.images.emojis.smileWithHearts} alt="emodji" class="ml-8" /></span>`,
        description: `Лист з промокодом <br />вже на Вашій пошті!`
    },
    sorryBonus: {
        title: `От халепа! Ви вже <br />наш підписник`,
        description: `Ми даємо бонуси тільки новим покупцям`,
        howGetMoreBonuses: `Як роздобути ще знижок Ви дізнаєтесь на <span class="d-flex align-items-center justify-content-center">
        <a href="${links.morkovki}" target="_blank" class="text-decoration-none">цій сторінці </a> <img src=${links.images.emojis.smile} class="ml-8" alt="emodji" /></span>`
    }
};
