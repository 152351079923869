/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    title: `Лекарства при беременности и кормлении грудью: узнайте, как препараты влияют на течение беременности и здоровье малыша`,
    subtitle: `Наша команда создала этот справочник, чтобы вы могли найти информацию о влиянии лекарственных средств на состояние беременной женщины, а также на младенца в период грудного вскармливания`,
    drugs: {
        title: `Лекарственные препараты, информацию о которых чаще всего ищут в период беременности и кормления грудью`
    },
    warning: {
        consult: `Всегда консультируйтесь со своим врачом, чтобы убедиться, что информация, отображаемая на этой странице, учитывает индивидуальные особенности вашего организма и состояния. Постановка диагноза и выбор методики лечения осуществляется только вашим лечащим врачом!`,
        responsibility: `apteka24.ua не несет ответственности за последствия, возникшие в результате использования информации, размещенной на сайте apteka24.ua. Подробнее об <a href="${links.responsibility}" target="_blank">Отказе от ответственности</a>.`
    },
    recommendations: {
        title: `Рекомомендуем ознакомиться`
    },
    contraindications: `Большинство лекарственных средств противопоказаны либо нежелательны к применению во время беременности по причине их неблагоприятного воздействия на течение беременности и состояние плода. Это касается не только рецептурных, но также и безрецептурных препаратов и натуральных добавок. Поэтому каждая будущая мать должна знать об особенностях каждого лекарства, которое она принимает, чтобы избежать негативного влияния на ребенка. Помните, что первый триместр беременности — наиболее критичный период, когда применение большинства лекарств запрещены для беременных.`,
    dangers: `Кроме того, матери, кормящие грудью, должны помнить, что действующие вещества лекарств могут проникать в грудное молоко и таким образом попадать в организм ребенка, оказывая нежелательное воздействие на его здоровье. Применение некоторых лекарств может быть противопоказано в период грудного вскармливания.`
};
