/*eslint-disable max-len*/
import links from "app/core/resource/links";

export default {
    joinToUs: {
        title: "Интересный факт",
        descriptionSlideOne: `<span>751 928&nbsp;грн</span> наши клиенты сэкономили в течение 2021 года, оплачивая заказы бонусами`,
        descriptionSlideTwo: `147 клиентам начислено более <span>300&nbsp;бонусных&nbsp;грн</span> за отзывы в ноябре 2021 года`,
        descriptionSlideThree: `<span>17%&nbsp;скидки</span> в среднем получили клиенты, используя бонусы`,
        registration: {
            bonusTitle: `Зарегистрируйтесь и получите купон на&nbsp;<span>100&nbsp;грн&nbsp;скидки</span> на первый заказ`,
            terms: `Продолжая, вы принимаете условия <a href="${links.userAgreement}">пользовательского&nbsp;соглашения</a>`,
            welcome: `Привет👋🏻 на вашем счету:`,
            toShopping: `К покупкам`,
            bonus50UAH: `Теперь у вас есть бонусный счет, на который мы <span>начислили 50 грн!</span>`,
            bonusesInOwnCabinet: `Увидеть начисленные бонусы и статусы своих будущих заказов вы сможете в личном кабинете.`,
            ownCabinet: `личный кабинет`
        }
    },
    statistics: {
        clientsEconomyByBonusesValue: "751 928 грн",
        clientsEconomyMore100UAHValue: "541 покупатель",
        earnedBonusesByReviewsValue: "1 862 клиента",
        middleDiscountValue: "17% скидки",
        bonusesInMonthStartValue: "3 653 019 грн",
        getMore300UAHValue: "147 клиентов",
        clientsEconomyByBonusesDescription: "клиенты сэкономили в течение 2021 года, оплачивая заказы бонусами",
        clientsEconomyMore100UAHDescription: "сэкономил на своем заказе больше 100 грн в ноябре 2021&nbsp;г.",
        earnedBonusesByReviewsDescription: "заработали бонусы на отзывах о товарах в ноябре 2021 г.",
        middleDiscountDescription: "в среднем получили клиенты, используя бонусы",
        bonusesInMonthStartDescription: "бонусов начислено клиентам на начало ноября 2021 г.",
        getMore300UAHDescription: "получили за свои отзывы больше 300 грн бонусов"
    },
    howItWorks: {
        title: "Как это работает?",
        getBonuses: "Получайте бонусные гривны с каждой покупки",
        writeReviews: "Пишите отзывы о товарах, выполняйте задания и получайте ещё больше бонусов",
        useBonuses: "Используйте бонусы для оплаты заказов для себя или близких. <br> 1 морковка = 1 грн"
    },
    bonusesPayment: {
        economyTo: "Экономьте до 50%",
        fromCost: "от стоимости ваших покупок",
        description: `Бонусами можно оплачивать более 4014 товаров из каталога apteka24.ua – это товары со значком морковки <img src="${links.images.newLoyaltyProgram.bonusesPayment.carrot}" width="14" height="21" alt="carrot" />. Мы с производителями каждый день копаем грядки и добавляем в список новые товары.`,
        seeProducts: "смотреть товары"
    },
    delivery: {
        economyOnMedicines: "Экономия на лекарствах",
        cashback: "Кешбэк до 4% на всё",
        bonusesForReview: "Бонусы за отзывы",
        bonusesPayment: "Оплата бонусами до 50%",
        delivery: "Доставка от 0 грн удобным для вас способом",
        cardPayment: "Оплата заказов картой или наличными – при получении",
        profitTitle: "С нами выгодно",
        profitDescription: "Выбирайте то, что вам нужно, и мы привезем это в любой город Украины – курьером, службой доставки или в аптеку.<br><br>Совершайте покупки, оставляйте отзывы о лекарствах, консультируйтесь с фармацевтом и тратьте свои бонусы.",
        comfortTitle: "С нами удобно",
        comfortDescription: "Не переплачивайте за комфорт. Собирайте в один заказ все необходимые медикаменты и медицинские товары, которые раньше нужно было собирать по разным аптекам. Это удобно, особенно если получатель – ваша бабушка."
    },
    howKeepBonuses: {
        to120Bonuses: "До 120 бонусных гривен",
        usefulReview: "за полезный отзыв",
        get50UAH: "Зарегистрируйтесь в программе, чтобы получить 50 грн",
        cashback: "Делайте заказы на любую сумму и получайте кешбэк до 4%",
        reviews: "Пишите отзывы о товарах и получайте за каждый до 120 грн",
        howToKeep: "Как же накопить бонусы?"
    },
    getBonusesForReview: {
        title: "Как работают бонусы за отзывы",
        userTitle: "Зарегистрируйтесь или авторизуйтесь",
        bookTitle: "Оставьте отзыв о товаре",
        checkTitle: "Отправьте отзыв на проверку",
        carrotTitle: "Получите бонусы",
        userDescription: "на сайте или в мобильном приложении",
        bookDescription: "просто найдите товар, о котором вам есть что сказать",
        checkDescription: "в течение 48 часов мы проверим и опубликуем отзыв",
        carrotDescription: "от 10 грн за обычный до 50 грн за первый отзыв к товару"
    },
    reviewRules: {
        title: "Бонусы начисляются за любой отзыв?",
        answer: "Нет, только за хороший",
        description: "Мы читаем все отзывы наших пользователей и у нас есть правила, которыми мы руководствуемся, одобряя или отклоняя отзыв. Если ваш отзыв интересно читать, вы делитесь личным опытом о том, как товар (лекарство, добавка, оборудование) помог вам или вашим близким, если вы честно рассказываете о побочных эффектах или о бесполезности препарата – это хороший отзыв."
    },
    howToUse: {
        howToSee: "Как посмотреть",
        register: "Зарегистрируйтесь или авторизуйтесь на сайте или в приложении",
        seeInOwnCabinet: "Посмотрите в личном кабинете, сколько бонусов и за что вы получили",
        availableBonuses: "Бонусы по кешбэку становятся доступны через 14 дней после покупки",
        howToSpend: "Как потратить",
        clickUseBonuses: "Авторизуйтесь, наполните корзину и укажите «использовать бонусы» при оформлении заказа",
        economyTo50: "До 50% – экономия на товарах с «морковкой» при применении бонусов  1 бонусная гривна = 1 гривна",
        validity: "Срок действия бонусных гривен – 180 дней"
    },
    downloadApp: {
        title: "apteka24.ua<br>аптека у вас в кармане",
        description: "Скачайте мобильное приложение apteka24 на ваш смартфон"
    }
};
