export default {
    title: 'Забота о коже в течение всей жизни',
    babySkin: 'Кожа малыша',
    drySkin: 'Чрезмерная  сухость кожи',
    treatment: 'Лечение и заживление ран',
    bepantenProduction: 'Продукция Бепантен®',
    pruduction: {
        title: 'Больше 70 лет Бепантен защищает и лечит кожу.*',
        companyInfo: '* Proksch E et al. Topical use of dexpanthenol: a 70th anniversary article. Journal of Dermatological Treatment, 2017; 28 (8): 766-773.'
    },
    usage: {
        babySkin: 'Одна из самых удивительных и трогательных вещей, когда прикасаешься к крохе — это мягкость его кожи. Это значит, что коже младенцев требуется особенно тщательная и нежная забота',
        drySkin: 'В обыденной жизни на кожу влияют множество факторов, которые могут привести к ее сухости. Чтобы сберечь кожу здоровой, очень важно регулярно заботиться о ней',
        treatment: 'Здоровая кожа — наш защитный барьер. И когда барьерная функция кожи страдает, это делает кожу уязвимой к раздражениям, повреждениям и другим проблемам'
    },
    ointment: 'мазь',
    cream: 'крем',
    spray: 'спрей',
    products: {
        ointment: {
            name: 'Бепантен® Мазь',
            description: 'Способствует заживлению мелких ран и ожогов; рекомендован для ухода за кожей младенцев'
        },
        cream: {
            name: 'Бепантен® Крем',
            description: 'Крем увлажняет кожу и ускоряет восстановление клеток'
        },
        creamPlus: {
            name: 'Бепантен® Плюс Крем',
            description: 'Препарат обладает двойным действием, не раздражает поврежденную кожу, противостоит инфекциям'
        },
        spray: {
            name: 'Бепантен® Плюс Спрей',
            description: 'Спрей дезинфицирует и ускоряет восстановительные процессы'
        }
    },
    warning: 'Реклама лекарственных средств. Перед применением необходимо обязательно проконсультироваться с врачом и ознакомиться с инструкцией. Хранить в местах, недоступных для детей. Регистрационные удостоверения: Бепантен® Мазь UA/4157/02/01 от 30.11.2020; Бепантен® Крем UA/4157/01/01 от 30.11.2020; Бепантен® Плюс Крем РП № UA/7805/01/01 от 08.12.2017; Бепантен® Плюс Спрей накожный, раствор РП № UA/7805/02/01 от 08.12.2017. ООО «Байер», ул. Верхний Вал 4-Б, тел. (044) 220 33 00.'
};
