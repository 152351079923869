/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    title: "Аптека, в которой комфортно",
    image: `${links.images.advantages.header}`,
    description: {
        creatingPharmacy: `Мы шаг за шагом создаем аптеку, в которую хотели бы заходить сами: простую,
                           удобную и доступную`,
        listedAdvantages: `Ниже мы перечислили преимущества, которые помогут вам сделать правильный выбор`
    },
    sections: [
        {
            title: "Приятные цены",
            image: `${links.images.advantages.prices}`,
            text: "Цены на многие лекарства и товары для здоровья в нашей интернет-аптеке ниже, чем в обычных аптеках. Мы получаем товары напрямую от производителей, а затем доставляем вам заказы с собственных складов. Без посредников и перекупщиков."
        },
        {
            title: "Акции и скидки",
            image: `${links.images.advantages.discount}`,
            text: "С apteka24.ua можно хорошенько экономить! Производители лекарств и медицинских товаров регулярно запускают с нами акции – так товары можно заказывать с дополнительной скидкой. Такие товары вы найдете в разделе «Акции»."
        },
        {
            title: "Огромный ассортимент",
            image: `${links.images.advantages.assortment}`,
            text: "У нас огромное количество товаров – 20 000 наименований! Лекарства, витамины, медицинские приборы… У нас есть все – для мам и малышей; для красоты и спорта; для тех, кто собирает аптечку в отпуск или автолюбителей. Любые медицинские средства, даже самые редкие, – непременно найдутся!"
        },
        {
            title: "Аптека рядом с Вашим домом",
            image: `${links.images.advantages.nearHome}`,
            text: "У нас есть собственная сеть аптек и мы дружим с 1000 аптечных точек по всей Украине чтобы доставлять заказы ближе к Вам. У нас всегда можно выбрать доставку в аптеку рядом с домом или работой, а затем легко забрать заказ в подходящее время!"
        },
        {
            title: "Удобная и быстрая доставка",
            image: `${links.images.advantages.delivery}`,
            text: "Мы доставляем заказы через “Нову Пошту”, Укрпошту, Justin и Meest Express, чтобы дать удивительный сервис всем нашим клиентам. Минимальный срок доставки – всего один день. Вы можете делать заказы для себя или своих родителей, оплачивать их картой или наличными, заказывать доставку домой или в отделения, в города-миллионники или маленькие сёла.",
            link: "Подробнее о способах и ограничениях доставки.",
            href: `${links.delivery}`
        },
        {
            title: "Простой поиск лекарств",
            image: `${links.images.advantages.compare}`,
            text: "Поиск на apteka24.ua – простой и удобный. Каждый товар описан, есть инструкция и подробная информация о составе и дозировке. У нас есть блог с советами и страницы, посвященные лечению отдельных заболеваний. Прямо на сайте можно заказать видео-консультацию с врачом и даже записаться на приём."
        },
        {
            title: "Конфиденциально",
            image: `${links.images.advantages.confidentially}`,
            text: "При получении заказа в аптеке или через службу доставки Вам не придется даже произносить названия выбранных товаров — мы просто выдаём Вам заказ и никогда не нарушаем Ваше право на сохранение в тайне его содержимого или Ваших заболеваний."
        },
        {
            title: "Лицензированный товар",
            image: `${links.images.advantages.quality}`,
            text: `Мы работаем напрямую с крупнейшим поставщиком фарм-продукции в Украине – компанией <a href=${links.badm}>БаДМ</a>. <a href=${links.warranty}>Мы гарантируем качество всех товаров</a> и соблюдаем все правила хранения и транспортировки лекарственных препаратов. Наши товары имеют сертификаты и инструкции. Заказывая у нас Вы можете по-настоящему быть уверенны в качестве доставленного товара.`
        }
    ]
};
