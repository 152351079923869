/*eslint-disable max-len*/
import links from "app/core/resource/links";

export default {
    joinToUs: {
        title: "Цікавий факт",
        descriptionSlideOne: `<span>751 928&nbsp;грн</span> наші клієнти заощадили протягом 2021 року, оплачуючи замовлення бонусами`,
        descriptionSlideTwo: `147 клієнтам нараховано понад <span>300&nbsp;бонусних&nbsp;грн</span> за відгуки у листопаді 2021 року`,
        descriptionSlideThree: `<span>17%&nbsp;знижки</span> у середньому отримали клієнти, використовуючи бонуси`,
        registration: {
            bonusTitle: `Зареєструйтеся та отримайте купон на&nbsp;<span>100&nbsp;грн&nbsp;знижки</span> на перше замовлення`,
            terms: `Продовжуючи, ви приймаєте умови <a href="${links.userAgreement}">угоди&nbsp;користувача</a>`,
            welcome: `Привіт👋🏻 на вашому рахунку:`,
            toShopping: `до покупок`,
            bonus50UAH: `Тепер у вас є бонусный рахунок, на який мы <span>нарахували 50 грн!</span>`,
            bonusesInOwnCabinet: `Побачити нараховані бонуси та статуси своїх майбутніх замовлень ви зможете в особистому кабінеті.`,
            ownCabinet: `особистий кабінет`
        }
    },
    statistics: {
        clientsEconomyByBonusesValue: "751 928 грн",
        clientsEconomyMore100UAHValue: "541 покупець",
        earnedBonusesByReviewsValue: "1 862 клієнти",
        middleDiscountValue: "17% знижки",
        bonusesInMonthStartValue: "3 653 019 грн",
        getMore300UAHValue: "147 клієнтів",
        clientsEconomyByBonusesDescription: "клієнти заощадили протягом 2021 року, оплачуючи замовлення бонусами",
        clientsEconomyMore100UAHDescription: "заощадив на своєму замовленні понад 100 грн у листопаді 2021&nbsp;р.",
        earnedBonusesByReviewsDescription: "заробили бонуси на відгуках про товари у листопаді 2021 р.",
        middleDiscountDescription: "у середньому отримали клієнти, використовуючи бонуси",
        bonusesInMonthStartDescription: "бонусів нараховано клієнтам на початок листопада 2021 р.",
        getMore300UAHDescription: "отримали за свій відгук понад 300 грн бонусів"
    },
    howItWorks: {
        title: "Як це працює?",
        getBonuses: "Отримуйте бонусні гривні з кожної покупки",
        writeReviews: "Пишіть відгуки про товари, виконуйте завдання та отримуйте ще більше бонусів",
        useBonuses: "Використовуйте бонуси для оплати замовлень для себе та близьких.<br>1 морквинка = 1 грн"
    },
    bonusesPayment: {
        economyTo: "Заощаджуйте до 50%",
        fromCost: "від вартості ваших покупок",
        description: `Бонусами можна оплачувати понад 4014 товарів із каталогу apteka24.ua — це товари зі значком морквинки <img src="${links.images.newLoyaltyProgram.bonusesPayment.carrot}" width="14" height="21" alt="carrot" />. Ми із виробниками кожен день копаємо грядки та додаємо в список нові товари.`,
        seeProducts: "дивитися товари"
    },
    delivery: {
        economyOnMedicines: "Економія на ліках",
        cashback: "Кешбек до 4% на все",
        bonusesForReview: "Бонуси за відгуки",
        bonusesPayment: "Оплата бонусами до 50%",
        delivery: "Доставка від 0 грн зручним для вас способом",
        cardPayment: "Оплата замовлень карткою або готівкою — при отриманні замовлення",
        profitTitle: "З нами вигідно",
        profitDescription: "Обирайте те, що вам потрібно, і ми привеземо це у будь-яке місто України — кур'єром, службою доставки чи в аптеку.<br><br>Здійснюйте покупки, залишайте відгуки про ліки, консультуйтеся з фармацевтом та витрачайте свої бонуси.",
        comfortTitle: "З нами зручно",
        comfortDescription: "Не переплачуйте за комфорт. Збирайте в одне замовлення усі необхідні медикаменти та медичні товари, які раніше потрібно було збирати з різних аптек. Це зручно, особливо якщо отримувач — ваша бабуся."
    },
    howKeepBonuses: {
        to120Bonuses: "До 120 бонусних гривень",
        usefulReview: "за корисний відгук",
        get50UAH: "Зареєструйтеся в програмі, щоб отримати 50 грн",
        cashback: "Робіть замовлення на будь-яку суму та отримуйте кешбек до 4%",
        reviews: "Пишіть відгуки про товари та отримуйте за кожний до 120 грн",
        howToKeep: "Як же накопичити бонуси?"
    },
    getBonusesForReview: {
        title: "Як працюють бонуси за відгуки",
        userTitle: "Зареєструйтеся або авторизуйтеся",
        bookTitle: "Залиште відгук про товар",
        checkTitle: "Відправте відгук на перевірку",
        carrotTitle: "Отримайте бонуси",
        userDescription: "на сайті або у мобільному додатку",
        bookDescription: "просто знайдіть товар, про який вам є що розповісти",
        checkDescription: "протягом 48 годин ми перевіримо та опублікуємо відгук",
        carrotDescription: "від 10 грн за звичайний і до 50 грн за перший відгук до товару"
    },
    reviewRules: {
        title: "Бонуси нараховуються за будь-який відгук?",
        answer: "Ні, тільки за хороший",
        description: "Ми читаємо всі відгуки наших користувачів і у нас є правила, якими ми керуємося, схвалюючи чи відхиляючи відгук. Якщо ваш відгук цікаво читати, ви ділитеся власним досвідом про те, як товар (ліки, добавка, обладнання) допоміг вам чи вашим близьким, якщо ви чесно розповідаєте про побічні ефекти або про марність препарату — це гарний відгук."
    },
    howToUse: {
        howToSee: "Як подивитися",
        register: "Зареєструйтеся чи авторизуйтеся на сайті або у додатку",
        seeInOwnCabinet: "Подивіться в особистому кабінеті, скільки бонусів і за що ви отримали",
        availableBonuses: "Бонуси за кешбеком стають доступними через 14 днів після покупки",
        howToSpend: "Як витрачати",
        clickUseBonuses: "Авторизуйтеся, наповніть кошик та вкажіть «використати бонуси» при оформленні замовлення",
        economyTo50: "До 50% — економія на товарах з «морквинкою» при використанні бонусів 1 бонусна гривня = 1 гривня",
        validity: "Термін дії бонусних гривень — 180 днів"
    },
    downloadApp: {
        title: "apteka24.ua<br>аптека у вас у кишені",
        description: "Завантажте мобільний додаток apteka24.ua до вашого смартфону"
    }
};
