export default {
    title: 'Піклування про шкіру впродовж всього життя',
    babySkin: 'Шкіра малюка',
    drySkin: 'Надмірна сухість шкіри',
    treatment: 'Лікування та загоєння ран',
    bepantenProduction: 'Продукція Бепантен®',
    pruduction: {
        title: 'Більше 70 років Бепантен® захищає та лікує шкіру.*',
        companyInfo: '* Proksch E et al. Topical use of dexpanthenol: a 70th anniversary article. Journal of Dermatological Treatment, 2017; 28 (8): 766-773.'
    },
    usage: {
        babySkin: 'Одна з найдивовижніших речей, що зворушує, коли торкаєшся немовлятка, – це м\'якість його шкіри. Це означає, що шкірі немовлят необхідне особливо дбайливе й ніжне піклування',
        drySkin: 'У повсякденному житті на шкіру впливають багато факторів, котрі можуть призвести до її сухості. Аби зберегти шкіру здоровою, дуже важливо регулярно піклуватися про неї',
        treatment: 'Здорова шкіра – наш захисний бар’єр. Проте інколи бар’єрна функція шкіри страждає. Це робить шкіру вразливою до подразнень, пошкоджень та інших проблем'
    },
    ointment: 'мазь',
    cream: 'крем',
    spray: 'спрей',
    products: {
        ointment: {
            name: 'Бепантен® Мазь',
            description: 'Сприяє загоєнню дрібних ран і опіків; рекомендований  для догляду за шкірою немовляти'
        },
        cream: {
            name: 'Бепантен® Крем',
            description: 'Крем зволожує шкіру та прискорює відновлення  клітин'
        },
        creamPlus: {
            name: 'Бепантен® Плюс Крем',
            description: 'Препарат має подвійну дію, не подразнює пошкоджену шкіру, протидіє інфекції'
        },
        spray: {
            name: 'Бепантен® Плюс Спрей',
            description: 'Спрей дезінфікує та прискорює відновні процеси'
        }
    },
    warning: 'Реклама лікарських засобів. Перед застосуванням необхідно обов’язково проконсультуватися з лікарем та ознайомитися з інструкцією. Зберігати в місцях, недоступних для дітей. Реєстраційні посвідчення: Бепантен® Мазь UA/4157/02/01 від 30.11.2020; Бепантен® Крем UA/4157/01/01 від 30.11.2020; Бепантен® Плюс Крем РП № UA/7805/01/01 від 08.12.2017; Бепантен® Плюс Спрей нашкірний, розчин РП № UA/7805/02/01 від 08.12.2017. ТОВ «Байєр», вул. Верхній Вал 4-Б, тел. (044) 220 33 00.'
};
