/* eslint-disable max-len */
export default {
    about: {
        title: `Электронная медицинская информационная система apteka24.ua — почти как интернет-аптека, только лучше!`,
        description: `
            <P>
                Электронная медицинская информационная система apteka24.ua — это поиск медицинских товаров широкого ассортимента в аптеках вашего города, низкие цены на лекарства и удобная доставка. Возможно, на первый взгляд мы в чём-то похожи на интернет-аптеку, но на самом деле на apteka24.ua вы сможете найти больше лекарств и больше удобных способов получения заказа.
            </P>

            <P>
                Получить свой заказ вы можете в любой партнерской аптеке в вашем городе — это может быть ближайшая аптека к вашей работе или дому. Или же вы можете заказать курьерскую доставку и получить товары там, где вам удобно, и тогда, когда вам удобно.
            </P> 
            
            <P>
                Все мед.препараты наших аптек-партнеров имеют сертификаты качества, а также хранятся и перевозятся согласно необходимым условиям хранения и транспортировки и температурному режиму. Поэтому вы можете быть уверены, что заказываете качественные препараты — как оригиналы, так и дженерики.
            </P>
            
            <P>
                Звоните по бесплатному номеру 0800 30 22 44, и вы получите подробную информацию о наличии лекарств в аптеках вашего города от наших фармацевтов. Мы работаем для вашего здоровья!
            </P>
        `,
        advantages: {
            title: `В чем преимущества apteka24.ua?`,
            hugeAssortment: `Более 20 тысяч медпрепаратов из каталога можно заказать онлайн для получения в ближайшей к вам аптеке. Здесь представлены как популярные, так и редкие лекарственные средства, которые трудно найти в большинстве аптечных пунктов. Перечень доступных товаров постоянно расширяется новыми наименованиями.`,
            delivery: `Несмотря на то, что мы не являемся интернет-аптекой, мы можем организовать доставку в Украине. Заказ можно получить в кратчайшие сроки в любом населенном пункте при помощи курьерских служб «Укрпошта» и «Нова Пошта».`,
            discounts: `Скидки на востребованные лекарства. Цена снижается в осенне-зимний сезон на противопростудные медикаменты, а летом — на противоаллергические.`,
            qualityCertificates: `Продукция обладает сертификатами качества, которые можно заказать в аптеке, чтобы убедиться в надежности при получении.`,
            medicalMarket: `Больше, чем просто аптека. Помимо прочего, мы являемся информационным порталом с полезным и информативным блогом, который ведет команда медицинских экспертов, которая, к тому же,  консультирует наших клиентов.`
        },
        assortment: {
            title: `Какие товары можно приобрести через apteka24.ua?`,
            productsFromCatalog: `Вы можете обратиться к нам с готовым рецептом врача, который специалисты-фармацевты контакт-центра apteka24.ua расшифруют и сформируют точный заказ. Или же вы можете самостоятельно подобрать нужные лекарства и заказать онлайн в ближайшую аптеку.`,
            productsHasInstruction: `Все медикаменты содержат точное изображение упаковки препарата и подробную инструкцию со всей необходимой медицинской информацией. Это позволит легко определиться с выбором.`,
            youCanFindAllDrugs: `У вас есть возможность купить все необходимые лекарства, заказав их через электронную медицинскую информационную систему apteka24.ua, будто побывав в интернет-аптеке. Широкий выбор товаров аптечного ассортимента представлен следующими категориями:`,
            drugList: `
                <li>
                    препараты от простуды, антибиотики, гинекологические, гематологические и гормональные препараты, средства от аллергии, для красивой кожи, сердечно-сосудистой системы, гепатопротекторы, препараты для нервной системы и обезболивающие средства;                
                </li>
                
                <li>
                    витамины и диетические добавки для крепкого здоровья;                
                </li>
                
                <li>
                    продукция для мам и детей (приспособления для кормления, продукция, повышающая лактацию, и разнообразные детские чаи);                
                </li>
                
                <li>
                    косметика и гигиеническая продукция по уходу за собой;                
                </li>
                
                <li>
                    товары медицинского назначения (медицинская техника, манипуляционные, диагностические и перевязочные средства, ортопедические приспособления, компрессионный трикотаж и медицинская одежда);                
                </li>
                
                <li>
                    продукция для здоровья глаз (контактные линзы и капли для глаз) и прочее.                
                </li>
            `
        },
        howToGetOrder: {
            title: `Как получить заказ, оформленный онлайн?`,
            suggestMethods: `Мы предлагаем следующие способы получения заказов:`,
            suggestMethodsList: `<li>самовывоз из аптек-партнеров;</li>
                            <li>службы доставки «Укрпошта» и «Нова пошта».</li>`,
            responsibility: `Когда вы заказываете лекарства онлайн через электронную медицинскую информационную систему apteka24.ua, вы можете быть уверены, что получите заказ с сертифицированными товарами в удобное время и в удобном для вас месте, так как мы сотрудничаем с несколькими сотнями аптек-партнеров, благодаря которым клиенты получают возможность бронирования лекарств с полки ближайшей аптеки.`
        }
    }
};
