/* eslint-disable max-len */

export default {
    whoCan: {
        allowed: `Статус «Дозволено» означає, що даний препарат має мінімальний ризик для здоров'я у даної групи населення. Очікувана користь від прийому перевищує можливі ризики. Проте, всі лікарські засоби ми рекомендуємо застосовувати під наглядом лікаря. Нагадуємо, що самолікування може бути небезпечним для вашого здоров'я.`,
        prohibited: `Статус «Заборонено» означає, що ризики при застосуванні таких препаратів завжди перевищують очікувану користь для цієї групи населення. Ми рекомендуємо відмовитися від застосування даних лікарських засобів.`,
        carefully: `Статус «З обережністю» означає, що даний препарат має підвищений ризик для здоров'я у даної групи населення. Застосування цих ліків допустимо, якщо очікувана користь від прийому буде перевищувати можливі ризики. Застосування таких препаратів може здійснюватися тільки за призначенням лікаря. `,
        noData: `Статус «Дані відсутні» означає, що достовірна інформація про особливості застосування препарату у даної групи населення відсутня, вплив маловивчений або клінічні дослідження не проводилися чи проводилися в обмеженій кількості, що не дозволяє вважати отримані дані достовірними.`
    },
    prescription: {
        withPrescription: `Препарат відпускається за рецептом. При отриманні товару фармацевт може попросити вас показати рецепт. Застосування таких засобів обов'язково має відбуватися під контролем лікаря.`,
        withoutPrescription: `Препарат відпускається без рецепта. Однак ми рекомендуємо вам перед застосуванням будь-яких медикаментів, навіть безрецептурних, проконсультуватися з лікарем, щоб уникнути помилок лікування.`
    },
    alcohol: {
        minimal: `Взаємодія з алкоголем мінімально значуща. Застосування препарату разом з алкоголем небажано і вимагає моніторингу з боку лікаря, тому що існує незначний ризик негативної взаємодії.`,
        moderate: `Взаємодія з алкоголем помірно значуща. Зазвичай потрібно уникати сумісного застосування даного препарату і алкоголю, тому що при взаємодії ризик перевищує користь. Застосування допустимо тільки в рідкісних випадках, мінімальних дозах і під контролем лікаря.`,
        critical: `Взаємодія з алкоголем критично значуща. Необхідно уникати сумісного застосування даного препарату і алкоголю, тому що при їх взаємодії ризик для організму значно перевищує користь.`,
        noData: `Інформація про взаємодію даного препарату і алкоголю невідома, тому їх спільне застосування не рекомендується, щоб уникнути можливих ризиків для здоров'я.`
    },
    temperature: {
        from5to25: `Зберігати даний препарат можна тільки при кімнатній температурі у діапазоні від 5 до 25 °С.`,
        from8to15: `Зберігати даний препарат можна тільки в прохолодному місці при температурі від 8 до 15 °С, наприклад, на верхніх полицях дверцят холодильника.`,
        from2to8: `Зберігати даний препарат можна тільки в холодному місці при температурі від 2 до 8 °С, наприклад, у холодильнику.`,
        fromMinus5toMinus18: `Зберігати даний препарат можна тільки в морозильній камері при температурі від -5 до -18 °С.`,
        below18: `Зберігати даний препарат можна тільки при глибокому заморожуванні при температурі нижче -18 °С.`
    },
    children: `Препарат можна застосовувати дітям із зазначеного віку. Перед прийомом проконсультуйтеся з лікарем. Застосування будь-яких ліків у дітей молодше 2 років обов'язково за призначенням лікаря.`
};
