import _ from "lodash";

import Entity from "app/core/entities/Entity";

class Navigation extends Entity {
    /**
     * @public
     * @method isActive
     * @returns {boolean}
     */
    isActive() {
        return Boolean(this.entity.active);
    }

    /**
     * @public
     * @method getTitle
     * @returns {string}
     */
    getTitle() {
        return this.entity.title || "";
    }

    /**
     * @public
     * @method getUrl
     * @returns {string}
     */
    getUrl() {
        return this.entity.url || "";
    }

    /**
     * @public
     * @method getIconUrl
     * @returns {string}
     */
    getIconUrl() {
        return this.entity.icon?.url || "";
    }

    /**
     * @public
     * @method getIconClassName
     * @returns {string}
     */
    getIconClassName() {
        return this.entity.icon.className || "";
    }

    /**
     * @public
     * @method getItems
     * @returns {Array}
     */
    getItems() {
        return [];
    }

    /**
     * @public
     * @method setActive
     * @param value {boolean}
     * @returns {Navigation}
     */
    setActive(value) {
        this.entity.active = Boolean(value);

        return this;
    }

    /**
     * @public
     * @method getCount
     * @returns {number}
     */
    getCount() {
        return Number(this.entity.count) || 0;
    }

    /**
     * @public
     * @method setCount
     * @param value {number}
     * @returns {Navigation}
     */
    setCount(value) {
        if (_.isNumber(value)) {
            this.entity.count = value;
        }

        return this;
    }
}

export default Navigation;
