import Enum from "app/core/utilites/enum/Enum";

let localStorageNameEnum = new Enum({
    fields: [
        {key: "token", value: "token"},
        {key: "city", value: "city"},
        {key: "basketId", value: "basketId"},
        {key: "cookieAgreement", value: "gdpr2"},
        {key: "viewedProducts", value: "viewedProducts"},
        {key: "closeInformerInFacetFilterInCatalog", value: "ciffc"},
        {key: "welcomeBonusPopup", value: "welcomeBonus"},
        {key: "blogSubscribersPopup", value: "blogSubscribers"},
        {key: "vendorId", value: "vendorId"}
    ]
});

export default {
    getInstance() {
        return localStorageNameEnum;
    }
};
