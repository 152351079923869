export default {
    farkos: {},
    johnson: {
        delivery: {
            title: `Джонсон і Джонсон Україна`,
            buyingAnyProduct: `При купівлі акційного товару`,
            discountTerm: `Акція діє до 31.03.2021 на сайті apteka24.ua`
        }
    }
};
