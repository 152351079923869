/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    title: `Медицинские эксперты`,
    description: `<p>apteka24.ua сотрудничает с высококвалифицированными медицинскими экспертами с целью обеспечения надежности, релевантности, достоверности и научной доказательности всех материалов, опубликованных на сайте apteka24.ua. Мы уделяем особое внимание тому, чтобы содержание контента, товары и услуги соответствовали самым высоким медицинским стандартам.</p>
                <p>Изменения медицинской отрасли, появление новых научных данных и современные открытия ученых становятся причиной того, что в сети становится слишком много устаревшей и неподтвержденной информации, которая может вводить в заблуждение. apteka24.ua считает, что в медицине и в вопросах здоровья это недопустимо.</p>
                <p>Поэтому мы собрали специальную команду высококвалифицированных врачей различных специализаций и фармацевтов, которые вместе с нами преследуют цель дать людям возможность получать только точную, достоверную, научно доказанную, обоснованную, актуальную и релевантную информацию медицинского и научного характера.</p>
                <p>Весь контент на сайте apteka24.ua (как давно существующий, так и новый) проверен, откорректирован, обновлен по мере поступления новых научных и официальных данных, а также утвержден нашими медицинскими экспертами.</p>
                <p>Инструкции, описания, характеристики препаратов, а также статьи блога и тексты категорий каталога проверяют опытные врачи и фармацевты, специализирующиеся в области, освещаемой в каждом конкретном виде контента.</p>
                <p>Редакторская команда медицинских экспертов выступает цензорами сайта apteka24.ua, создает экспертные материалы и освещает проблемные вопросы здоровья в подробных интервью.</p>`,
    medicalCensorsOfMedicines: `<h2>Медицинские цензоры лекарственных средств</h2>
                <p>Неотъемлемая часть команды apteka24.ua — фармацевты, выступающие медицинскими цензорами информации, предоставляемой на нашем сайте в качестве описаний для лекарственных средств и товаров.</p>
                <p>Специалисты по фармакологии проверяют инструкции к препаратам и товарам медицинского назначения, их описание, характеристики и подбор аналогов, а также следят за тем, чтобы все инструкции на сайте соответствовали официальному источнику — <a href="${links.stateRegisterOfDrugs}" target="_blank">Государственному реестру лекарственных средств Украины</a>.</p>
                <p>Мы считаем, что инструкция к лекарственному средству — это его «паспорт», это руководство к действию и ключ к здоровью. А потому точность, полнота и доступность инструкции крайне важны — и мы это обеспечиваем верификацией всех карточек товаров согласно<a href="${links.editorialPolicy}" target="_blank"> редакционной политике </a>apteka24.ua.</p>`,
    medicalHealthInformationCensors: `<h2>Медицинские цензоры информирования по вопросам здоровья</h2>
                <p>Так как изменения в медицинской отрасли происходят очень быстро, появляются новые научные данные, а предыдущие положения со временем отвергаются, то весь контент apteka24.ua, информирующий по вопросам здоровья, проходит обязательную проверку и утверждение медицинскими цензорами, в роли которых выступают высококвалифицированные врачи с обширными научными познаниями и многолетней практикой.</p>
                <p>Команда медицинских экспертов гарантирует, что информирующий контент блога точен, актуален и ориентирован на пациента.</p>
                <p>В соответствии с<a href="${links.editorialPolicy}" target="_blank"> редакционной политике </a>статьи блога по вопросам медицины и здоровья проходят обязательную проверку и корректуру со стороны медицинских экспертов. Проверку и утверждение статей осуществляет врач соответствующей компетенции, специализирующийся на вопросах медицинской отрасли, к которой относится статья.</p>
                <p>При освещении спорных утверждений, не имеющих на данный момент доказательных исследовательских данных, мы ссылаемся на мнение и одобрение врача соответствующей компетенции.</p>
                <p>Также врачи из команды медицинских экспертов apteka24.ua ведут просветительскую деятельность, публикуют собственные авторские научные материалы и освещают наиболее важные вопросы здоровья в интервью.</p>`
};
