/* eslint-disable max-len */

export default {
    title: `Як працює фільтр «Кому дозволено»`,
    properties: {
        title: `Кожний лікарський засіб містить особливі характеристики «Кому дозволено», які наочно показують, чи можна приймати даний засіб наступним категоріям населення:`,
        items: {
            allergy: "Алергікам",
            drivers: "Водіям",
            lactating: "Годуючим жінкам",
            diabetics: "Діабетикам",
            pregnant: "Вагітним",
            child: "Дітям"
        }
    },
    howToUse: {
        title: `Як користуватися фільтром «Кому дозволено»`,
        firstTip: `Категорії «Медикаменти», «БАДи та вітаміни» і «Антибіотики» містять фільтр «Кому дозволено», який дозволяє швидко зорієнтуватися в препаратах і вибрати відображення на сторінці тільки тих товарів, які дозволені тій чи іншій категорії:`,
        secondTip: `<p>Якщо ви застосуєте, наприклад, фільтр «Вагітним», то в пошуці відобразяться тільки ті товари, які дозволені вагітним жінкам, а також ті, які можна застосовувати з обережністю. Засоби, які однозначно заборонені під час вагітності, відображатися не будуть.</p>
                    <p>Для спрощеної орієнтації у списку товарів, препарати, які допустимо приймати з обережністю, відображаються з відповідним маркуванням:</p>`
    },
    whatMeans: {
        title: `Що означає фільтр «Кому дозволено»?`,
        description: `Будь-які ліки потрібно приймати з обережністю, адже показання до застосування завжди індивідуальні. Тому на apteka24.ua кожен препарат в основних властивостях відображає, чи можна приймати даний лікарський засіб певним категоріям населення.`,
        tip: `Якщо ви перейдете в картку товару, то зможете детально дізнатися, чи можна вам приймати дані ліки, а при кліці на посилання ви побачите підказку з детальною інформацією про те, що означають статуси «дозволено», «з обережністю» і «заборонено».`
    },
    values: {
        allowed: {
            title: `Дозволено`,
            description: `Статус «Дозволено» означає, що даний препарат має мінімальний ризик для здоров'я у даної групи населення. Очікувана користь від прийому перевищує можливі ризики. Проте, всі лікарські засоби ми рекомендуємо застосовувати під наглядом лікаря. Нагадуємо, що самолікування може бути небезпечним для вашого здоров'я.`
        },
        disallowed: {
            title: `Заборонено`,
            description: `Статус «Заборонено» означає, що ризики при застосуванні таких препаратів завжди перевищують очікувану користь для цієї групи населення. Ми рекомендуємо відмовитися від застосування даних лікарських засобів.`
        },
        carefully: {
            title: `З обережністю`,
            description: `Статус «З обережністю» означає, що даний препарат має підвищений ризик для здоров'я у даної групи населення. Застосування цих ліків допустимо, якщо очікувана користь від прийому буде перевищувати можливі ризики. Застосування таких препаратів може здійснюватися тільки за призначенням лікаря.`
        },
        noData: {
            title: `Дані відсутні`,
            description: `Статус «Дані відсутні» означає, що достовірна інформація про особливості застосування препарату у даної групи населення відсутня, вплив маловивчений або клінічні дослідження не проводилися чи проводилися в обмеженій кількості, що не дозволяє вважати отримані дані достовірними.`
        }
    },
    children: {
        description: `У категорії <span class="f-weight-5">«Дітям»</span>, якщо препарат дозволено застосовувати з певного віку, вказується дана умова:`,
        title: `Дітям`,
        value: `з 12 років`
    },
    alert: `apteka24.ua надає вичерпну і надійну інформацію з питань медицини, здоров'я і благополуччя, однак постановка діагнозу і вибір методики лікування можуть здійснюватися тільки вашим лікарем! Самолікування може бути небезпечним для вашого здоров'я. apteka24.ua не несе відповідальності за можливі негативні наслідки, що виникли в результаті використання користувачами apteka24.ua інформації, розміщеної на сайті.`
};
