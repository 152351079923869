/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    email: {
        title: `<span class="f-weight-7">Подписчикам блога</span>`,
        description: `Скидка <span class="">50 грн </span>на следующий заказ в apteka24`
    },
    emailVerified: {
        title: `<span class="d-flex align-items-center justify-content-center">Ваш промокод активирован 
        <img src=${links.images.employeeDiscount.congratulations} class="ml-8" alt="emodji" /></span>`,
        description: `Заказывайте лекарства с доставкой <br /> по всей Украине со скидкой!`
    },
    bonusForEmail: {
        title: `<span class="d-flex align-items-center justify-content-center">Вы - лучше всех <img src=${links.images.emojis.smileWithHearts} alt="emodji" class="ml-8" /></span>`,
        description: `Письмо с промокодом <br />уже у Вас на почте!`
    },
    sorryBonus: {
        title: `Ох, батюшки! Вы <br />уже наш подписчик`,
        description: `Мы даем бонусы только новеньким`,
        howGetMoreBonuses: `Как раздобыть еще скидок узнайте на <span class="d-flex align-items-center justify-content-center">
        <a href="${links.morkovki}" target="_blank" class="text-decoration-none">этой странице </a> <img src=${links.images.emojis.smile} class="ml-8" alt="emodji" /></span>`
    }
};
