/* eslint-disable max-len */

import links from "app/core/resource/links";

export default {
    pharmacyDonatesMoney: `apteka24.ua дарит 250 000 грн «своим» на покупку лекарств`,
    leaveYourPhone: `Оставьте свой номер до старта программы и получите <span class="f-weight-5">от 100 до 1 000 грн</span> на оплату лекарств!`,
    discountForEmployees: `Cкидка на лекарства для «своих»`,
    invite: {
        from: `Приглашает вас в <strong>программу лояльности</strong> от сервиса apteka24.ua`,
        success: {
            description: "После проверки мы начислим Вам бонусы, случайно выбрав 100, 250, 500 или 1000 грн. Их сразу можно будет потратить на apteka24.ua"
        }
    },
    advantages: {
        cashback: `<b>Кешбэк 5%</b> на все заказы`,
        bonusForFriend: `<b>75 грн</b> за регистрацию`,
        freeDelivery: `Всегда <b>бесплатная</b> доставка`
    },
    infoBlock: {
        employeeCapabilities: `У Вас появляется возможность покупать лекарства со скидкой и на особых условиях. 
         Теперь, заказывая лекарства на apteka24.ua, Вы будете возвращать 5% от их стоимости на свой бонусный счет. 
         Бонусы можно тратить на оплату будущих заказов для себя, друзей или близких.`,
        deliveryTerms: `Доставка заказов на сумму от 100 грн будет для Вас всегда бесплатной. Мы доставляем заказы по 
        всей Украине: в 1000 аптек, курьерами, «Новой почтой» и «Укрпочтой». В Днепре доступна доставка за 
        2 часа, а когда эта услуга придет в другие города – вы будете первыми, кто сможет ей воспользоваться.`
    },
    howItWorks: {
        description: `Все бонусы, которые Вы получаете как участник программы, можно тратить на оплату товаров с 
        «морковкой» на apteka24.ua. Таких товаров сейчас больше 3000 и мы каждый день работаем над увеличением их 
        количества, заключая договора с производителями лекарств. Получая деньги от производителей, мы передаем их в 
        виде скидок и бонусов нашим покупателям. Эта простая схема позволяет нам быть одной из самых выгодных аптек в
        Украине.`,
        example: `Например, Вы заказываете лекарства на сумму 350 грн. С учетом бонуса, который Вы получили после 
        регистрации (скажем, 100 грн), заказ будет стоить Вам 250 грн. Добавьте сюда бесплатную доставку и 5% кешбэк,
        который Вы получите за заказ – вот Вы сэкономили ещё 67,50 грн. За каждый отзыв о купленных товарах мы платим
        ещё от 10 грн и по 1 грн за каждый лайк к Вашим отзывам. Так, сделав покупку всего на 350 грн, Вы сэкономили 29%
        и получили ещё 100-300 грн бонусов на оплату будущих заказов. Подробнее о программе для клиентов и о том как ещё 
        зарабатывать бонусы читайте на <a href=${links.morkovki} target="_blank" class="">этой странице</a>.`
    },
    wantMoreBenefits: {
        terms: `Чем дольше Вы остаётесь участником программы – тем больше преимуществ получаете. Так, с запуском новых 
        услуг, Вы будете первыми получать к ним доступ, а новые скидки всегда будут для Вас выше, чем для других 
        клиентов. И нам нужна Ваша помощь.`,
        tasks: `Команда apteka24.ua часто запускает новые продукты и сервисы, качество которых мы будем просить Вас 
        проверить. Это будут маленькие ежемесячные задания, выполняя которые, Вы сможете оставаться участником программы
        и даже зарабатывать дополнительные бонусы, информируя нас о проблемах, с которыми Вы столкнетесь.`
    },
    examplesOfTasks: {
        first: `Например, одним из первых новшеств, которые смогут протестировать участники, будет акция 
        «Приведи друга», а заданием будет привести троих друзей и получить за это 150 грн бонусов.`,
        second: `Когда мы запустим доставку за 2 часа в Вашем городе – мы попросим Вас помочь нам протестировать и ее, 
        рассказав, что мы могли бы улучшить. Скоро мы запустим поиск лекарств по штрих-коду, а позже – доставку лекарств
        по подписке.`,
        rightNowYouCan: {
            task1: `<b>30 бонусов</b> - проверить, удобно ли оставлять отзыв о товаре на сайте`,
            task2: `<b>20 бонусов</b> - написать отзыв, который соберет 20 лайков`,
            task3: `<b>5 бонусов</b> - поставить к чужим отзывам 5 лайков или дизлайков`
        }
    },
    partners: {
        moreThanThousandProducts: `<span>...и тысячи<br /> других товаров</span>`
    },
    launchingSoon: `Запуск скоро`
};
