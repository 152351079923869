export default {
    pocketPharmacy: {
        title: "Аптека у вас в кишені",
        description: "Знижка до 50% на ліки, медтовари, косметику та безліч іншого"
    },
    economy: {
        boldDescriptionLeft: "Встанови додаток ",
        descriptionLeft: "та отримай знижку 100 грн на першу покупку від 1000 грн по промокоду АРР100",
        boldDescriptionCenter: "Ексклюзивні акції — ",
        descriptionCenter: "знижка до 30% на сотні товарів",
        boldDescriptionRight: "До 50% економії ",
        descriptionRight: "з вигідною програмою лояльності"
    },
    advantage: {
        chatTitle: "Безкоштовний чат з досвіченим фармацевтом",
        chatDescription: "Ми поруч 24/7 для консультацій, підбору ліків та оформлення замовлень",
        barcodeTitle: "Пошук ліків за штрихкодом",
        barcodeDescription: "Наведіть камеру телефона на упаковку препарату,  щоб знайти його у нашому каталозі",
        dragsTitle: "12 000 препаратів у одному додатку",
        dragsDescription: "Більше, ніж у будь-якій аптеці. Враховуючи рідкісні та дефіцитні товари"
    },
    delivery: {
        deliveryTitle: "Зручна доставка: додому чи в улюблену аптеку",
        deliveryDescription: "Ми доставляємо замовлення в 1000+ аптек, кур`єрами додому і службами доставки «Нова пошта» і «Укрпошта» у відділення",
        pickup: "Самовивіз з <br> Аптеки",
        courier: "Кур`єр за <br> вашою адресою",
        post: "«Нова пошта» <br> до відділення",
        ukrpost: "«Укрпошта» <br> до відділення",
        locationTitle: "Працюємо по всій Україні",
        locationDescription: "Доставляємо замовлення у будь-який населений пункт — до 1 години у великих містах, до 3 днів у села"
    },
    downloadApp: {
        title: "Економити легше у додатку apteka24.ua"
    }
};
